import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import axios from "axios";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Paginator } from "primereact/paginator";
import { Link } from "react-router-dom";
import LoadingSpinner from "../Spinner/LoadingSpinner";
import { useHistory } from "react-router-dom";
import { MultiSelect } from "primereact/multiselect";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import * as GlobalEnumData from "./../../enums/GlobalEnum";
import exportData from "../../services/exportToExcelService";
import AccessControl from "../AccessControl";
import NoAccess from "../NoAccess";
import { Tooltip } from "primereact/tooltip";
import { RowsPerPageDropdown } from "../../enums/RowsPerPageOptions";
import { useReactToPrint } from "react-to-print";
import PrintCertificate from "../../services/printcertificate";
import userDetails from "../../services/userTokenDetails";
import * as Designations from "../../enums/Designations";
import * as Qualifications from "../../enums/Qualifications";
// import '../../src/assets/layout/certificate.css';
import { Dialog } from "primereact/dialog";
import "../../assets/layout/certificate.css";
import * as GlobalEnum from "../../enums/GlobalEnum";

const CertificateGiven = () => {
    // Hooks
    const [participantlist, setParticipantlist] = useState([]);
    const [ActionDisable, setActionDisable] = useState(true);
    const [id, setid] = useState(new Array());
    const [totalRecords, setTotalRecords] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedColumns, setSelectedColumns] = useState([]);
    const [dayOfEventScreen, setDayOfEventScreen] = useState();
    const [selectedParticipant, setSelectedParticipant] = useState(null);
    const [fullName, setfullName] = useState(null);
    const [filterEmail, setFilterEmail] = useState(null);
    const [filterMobileNo, setFilterMobileNo] = useState(null);
    const [filterCity, setFilterCity] = useState(null);
    const [filterState, setFilterState] = useState(null);
    const [filterCountry, setFilterCountry] = useState(null);
    const [filterBoardingCity, setFilterBoardingCity] = useState(null);
    const [filterHospitalName, setFilterHospitalName] = useState(null);
    const [filterCompany, setFilterCompany] = useState(null);
    const [filterAddress, setFilterAddress] = useState(null);
    const [filterSpeciality, setFilterSpeciality] = useState(null);
    const [certificatedata, setcertificatedata] = useState(new Array());
    const [certificateDialog, setcertificateDialog] = useState(false);
    const [certificateWADialog, setcertificateWADialog] = useState(false);

    const [InBatchfilter, setInBatchfilter] = useState(null);
    const [OutBatchfilter, setOutBatchfilter] = useState(null);
    const [SerialNumber, setSerialNumber] = useState(null);
    const [isExportExcel, setIsExportExcel] = useState(false);

    let eventName = null;
    const [delegateurl, setDelegateurl] = useState(null);
    const [facultyurl, setFacultyurl] = useState(null);
    const [inBatchList, setInBatchList] = useState([]);
    const [outBatchList, setOutBatchList] = useState([]);
    const [tricoConfirmedList,setTricoConfirmedList] = useState([]);
    const [companyList,setCompanyList] = useState([]);

    // Const
    const toast = useRef(null);
    const componentRef = useRef();
    const dt = useRef(null);
    const history = useHistory();
    var usrDetails = userDetails();

    let DayOfEvent = "";
    let ModifyParticipantList = [];
    const UserName = localStorage.getItem("Username");

    const lazyState = useRef({
        first: 0,
        rows: RowsPerPageDropdown[0],
        page: 1,
        sortField: null,
        sortOrder: null,
        genderdropdowndata: null,
        filterMerchandiseIssued: null,
        filterCertificateIssued: null,
        filterPresent: null,
        fullName: null,
        filterHospitalName: null,
        filterEmail: null,
        filterMobileNo: null,
        filterCity: null,
        filterCountry: null,
        filterCardNo: null,
        filterAddress: null,
        filterSpeciality: null,
        filterBoardingCity: null,
        filterState: null,
        filterCompany: null,
        filterCountry: null,
        inbatchfilter: null,
        outbatchfilter: null,
        serialnumber: null,
        TricoConfirmedfilter: null,
        Designationfilter: null,
        Qualificationfilter:null,
        day2presentfilter: null
    }); // For Sorting And Pagination Purpose

    // On Screen Load
    useEffect(() => {
        getparticipantlist();
        getHeaders();
        document.documentElement.style.setProperty("overflow", "hidden");
        getFilterListsData();
    }, []);

    const getHeaders = () => {
        fetch('config.json'
            , {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }
        )
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                eventName = data[11].EventName;
                setDelegateurl('../images/' + eventName + '/certificate/certificate_delegate_blank.jpg');
                setFacultyurl('../images/' + eventName + '/certificate/certificate_faculty_blank.jpg');
            });
    };

    //#region Common Methods

    // - Get All Participant Api
    const getparticipantlist = (isExportExcel = false, event, IsPresent = null) => {
        //Designation
        var listDesignationFilterString = null;
        if (lazyState.current.Designationfilter != null && lazyState.current.Designationfilter != undefined && lazyState.current.Designationfilter != '') {
            if (lazyState.current.Designationfilter.length > 0) {
                if (lazyState.current.Designationfilter.length > 1) {
                    listDesignationFilterString = lazyState.current.Designationfilter.map(item => item.name).join(',');
                } else {
                    listDesignationFilterString = lazyState.current.Designationfilter.map(item => item.name)[0];
                }
            }
        }

        //Qualification
         var listQualificationFilterString = null;
         if (lazyState.current.Qualificationfilter != null && lazyState.current.Qualificationfilter != undefined && lazyState.current.Qualificationfilter != '') {
             if (lazyState.current.Qualificationfilter.length > 0) {
                 if (lazyState.current.Qualificationfilter.length > 1) {
                    listQualificationFilterString = lazyState.current.Qualificationfilter.map(item => item.name).join(',');
                 } else {
                    listQualificationFilterString = lazyState.current.Qualificationfilter.map(item => item.name)[0];
                 }
             }
         }
          
        //In Booking Batch
        var listInBatchFilterString = null;
        if (lazyState.current.inbatchfilter != null && lazyState.current.inbatchfilter != undefined && lazyState.current.inbatchfilter != '') {
            if (lazyState.current.inbatchfilter.length > 0) {
                if (lazyState.current.inbatchfilter.length > 1) {
                    listInBatchFilterString = lazyState.current.inbatchfilter.map(item => item.name).join(',');
                } else {
                    listInBatchFilterString = lazyState.current.inbatchfilter.map(item => item.name)[0];
                }
            }
        }

        //Out Booking Batch
        var listOutBatchFilterString = null;
        if (lazyState.current.outbatchfilter != null && lazyState.current.outbatchfilter != undefined && lazyState.current.outbatchfilter != '') {
            if (lazyState.current.outbatchfilter.length > 0) {
                if (lazyState.current.outbatchfilter.length > 1) {
                    listOutBatchFilterString = lazyState.current.outbatchfilter.map(item => item.name).join(',');
                } else {
                    listOutBatchFilterString = lazyState.current.outbatchfilter.map(item => item.name)[0];
                }
            }
        }

        //Trico Confirmed Batch
        var listTricoConfirmedFilterString = null;
        if (lazyState.current.TricoConfirmedfilter != null && lazyState.current.TricoConfirmedfilter != undefined && lazyState.current.TricoConfirmedfilter != '') {
            if (lazyState.current.TricoConfirmedfilter.length > 0) {
                if (lazyState.current.TricoConfirmedfilter.length > 1) {
                    listTricoConfirmedFilterString = lazyState.current.TricoConfirmedfilter.map(item => item.name).join(',');
                } else {
                    listTricoConfirmedFilterString = lazyState.current.TricoConfirmedfilter.map(item => item.name)[0];
                }
            }
        }

        //Company
        var listCompanyFilterString = null;
        if (lazyState.current.CompanyFilter != null && lazyState.current.CompanyFilter != undefined && lazyState.current.CompanyFilter != '') {
            if (lazyState.current.CompanyFilter.length > 0) {
                if (lazyState.current.CompanyFilter.length > 1) {
                    listCompanyFilterString = lazyState.current.CompanyFilter.map(item => item.name).join(',');
                } else {
                    listCompanyFilterString = lazyState.current.CompanyFilter.map(item => item.name)[0];
                }
            }
        }
        setIsLoading(true);
        if (!isExportExcel) setParticipantlist([]);
        axios
            .post("/api/Participant/GetAll", {
                paginationRequired: !isExportExcel,
                SortDirection: lazyState.current.sortOrder,
                PageSize: lazyState.current.rows,
                CurrentPageNumber: event && event.rows ? event.first / event.rows + 1 : lazyState.current.page,
                SortField: event && event.sortField ? event.sortField : "Firstname",
                registrationStatus: "APPROVED",
                CompanyFilter: listCompanyFilterString ? listCompanyFilterString : null,
                FullName: lazyState.current.fullName ? lazyState.current.fullName.trim() : null,
                HospitalName: lazyState.current.filterHospitalName ? lazyState.current.filterHospitalName.trim() : null,
                Email: lazyState.current.filterEmail ? lazyState.current.filterEmail.trim() : null,
                MobileNo: lazyState.current.filterMobileNo ? lazyState.current.filterMobileNo.trim() : null,
                Designation: listDesignationFilterString ? listDesignationFilterString : null,
                Qualification:listQualificationFilterString ? listQualificationFilterString : null,
                City: lazyState.current.filterCity ? lazyState.current.filterCity.trim() : null,
                CertificateIssued: lazyState.current.filterCertificateIssued ? lazyState.current.filterCertificateIssued.code.trim() : null,
                Address: lazyState.current.filterAddress ? lazyState.current.filterAddress.trim() : null,
                Speciality: lazyState.current.filterSpeciality ? lazyState.current.filterSpeciality.trim() : null,
                BoardingCity: lazyState.current.filterBoardingCity ? lazyState.current.filterBoardingCity.trim() : null,
                State: lazyState.current.filterState ? lazyState.current.filterState.trim() : null,
                country: lazyState.current.filterCountry ? lazyState.current.filterCountry.trim() : null,
                InBatchBooking: listInBatchFilterString ? listInBatchFilterString : null,
                OutBatchBooking: listOutBatchFilterString ? listOutBatchFilterString : null,
                SerialNumber: lazyState.current.serialnumber ? lazyState.current.serialnumber.trim() : null,
                TricoConfirmed: listTricoConfirmedFilterString ? listTricoConfirmedFilterString : null,
                DayTwoPresent:lazyState.current.day2presentfilter ? lazyState.current.day2presentfilter.code : null,
                // Present: window.location.href.includes("certificategiven") ? 'true' : (IsPresent != null ? IsPresent.toString() : lazyState.current.filterPresent ? lazyState.current.filterPresent.code.trim() : null),
                Present: IsPresent != null ? IsPresent.toString() : lazyState.current.filterPresent ? lazyState.current.filterPresent.code.trim() : null,
            })
            .then((Response) => {
                DayOfEvent = "Certificate Given";
                setDayOfEventScreen("Certificate Given");
                if (!isExportExcel) setParticipantlist([]);
                let tempData = [];
                for (let dataObj of Response.data.data) {
                    // Pass body data in excelsheets.
                    let allExcelData;
                    let excelcolumnData = {
                        Id: dataObj.serialNumber,
                        Name: dataObj.fullName,
                        InBatch: dataObj.inBatchBooking,
                        OutBatch: dataObj.outBatchBooking,
                        TricoConfirmed: dataObj.tricoConfirmed,
                        Designation:dataObj.designation,
                        Qualification:dataObj.qualification,
                        Company: dataObj.company,
                        Email: dataObj.email,
                        "Day-1 Present" : dataObj.present,
                        "Day-2 Present" : dataObj.dayTwoPresent,
                        "Mobile No": dataObj.mobileNo,
                        "Certificate Issue": dataObj.certificateIssued == true ? "Yes" : dataObj.certificateIssued == false ? "No" : "",
                        // Hospital: dataObj.hospitalName,
                        // "Address": dataObj.address,
                        // "City": dataObj.cityname,
                    };
                    if (selectedColumns.length > 0) {
                        for (let selectedColumn of selectedColumns) {
                            let fieldName = selectedColumn.field;
                            let headerName = selectedColumn.header;
                            excelcolumnData = { ...excelcolumnData, [headerName]: dataObj[fieldName] };
                        }
                        tempData.push(excelcolumnData);
                    } else {
                        allExcelData = {
                            Id: dataObj.serialNumber,
                            Name: dataObj.fullName,
                            InBatch: dataObj.inBatchBooking,
                            OutBatch: dataObj.outBatchBooking,
                            TricoConfirmed: dataObj.tricoConfirmed,
                            Designation:dataObj.designation,
                            Qualification:dataObj.qualification,
                            Company: dataObj.company,
                            Email: dataObj.email,
                            "Day-1 Present" : dataObj.present,
                            "Day-2 Present" : dataObj.dayTwoPresent,
                            "Mobile No": dataObj.mobileNo,
                            "Certificate Issue": dataObj.certificateIssued == true ? "Yes" : dataObj.certificateIssued == false ? "No" : "",
                            // "Address": dataObj.address,
                            // "City": dataObj.cityname,
                            // Hospital: dataObj.hospitalName,
                        };
                        tempData.push(allExcelData);
                    }
                }
                if (isExportExcel) {
                    exportData(tempData, DayOfEvent);
                    setIsLoading(false);
                    return false;
                }
                setTotalRecords(Response.data.totalRecords);
                ModifyParticipantList = Response.data.data.map((TricoConfirmedData) => ({
                    ...TricoConfirmedData,
                    certificateIssued: TricoConfirmedData.certificateIssued === true ? "Yes" : TricoConfirmedData.certificateIssued === false ? "No" : "",
                }));
                setParticipantlist(ModifyParticipantList);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                toast.current.show({ severity: "error", summary: "Error", detail: "Oops! Something Went Wrong!", life: 3000 });
            });
    };

    //Print Certificate
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: "Trico Certificate",
    });

    //pagination
    const onBasicPageChange = (event) => {
        // const newPage = event.first / event.rows + 1;
        // if (newPage !== lazyState.current.first / lazyState.current.rows + 1) {
        setIsLoading(true);
        lazyState.current.sortField = lazyState.current.sortField;
        lazyState.current.sortOrder = lazyState.current.sortOrder;
        lazyState.current.first = event.first;
        lazyState.current.rows = event.rows;
        getparticipantlist(false, lazyState.current, null);
        setIsLoading(false);
        // };
    };

    //mail certificate to selected participant
    const SendCertificatetoSelectedParticipant = (ids) => {
        setIsLoading(true);
        axios
            .get("/api/Participant/SendCertificateToSelectedParticipant?id=" + ids)
            .then((response) => {
                setIsLoading(false);
                hideCertificateDialog(false);
                id.splice(0, id.length);
                setSelectedParticipant([]);
                // history.push("/home/dayofevent/certificategiven");
                getparticipantlist(false, lazyState.current, null);
                setActionDisable(true);
                return toast.current.show({ severity: "success", summary: "Success", detail: "Certificate Sent Successfully", life: 3000 });
            })
            .catch((error) => {
                setIsLoading(false);
                toast.current.show({ severity: "error", summary: "Error", detail: "Oops! Something Went Wrong!", life: 3000 });
            });
    };

    //WhatsApp certificate to selected participant
    const SendCertificatetoSelectedParticipantWhatsApp = (ids) => {
        setIsLoading(true);
        axios
            .get("/api/Participant/SendCertificateToSelectedParticipantWhatsApp?id=" + ids)
            .then((response) => {
                setIsLoading(false);
                hideCertificateWADialog(false);
                id.splice(0, id.length);
                setSelectedParticipant([]);
                // history.push("/home/dayofevent/certificategiven");
                getparticipantlist(false, lazyState.current, null);
                setActionDisable(true);
                return toast.current.show({ severity: "success", summary: "Success", detail: "Certificate Sent Successfully", life: 3000 });
            })
            .catch((error) => {
                setIsLoading(false);
                toast.current.show({ severity: "error", summary: "Error", detail: "Oops! Something Went Wrong!", life: 3000 });
            });
    };

    const SendCertificatetoAll = () => {
        setIsLoading(true);
        axios
            .get("/api/Participant/SendCertificateToAll")
            .then((response) => {
                setIsLoading(false);
                return toast.current.show({ severity: "success", summary: "Success", detail: "Certificate Sent Successfully", life: 3000 });
            })
            .catch((error) => {
                setIsLoading(false);
                toast.current.show({ severity: "error", summary: "Error", detail: "Oops! Something Went Wrong!", life: 3000 });
            });
    };

    const WhatsAppCertificatetoAll = () => {
        setIsLoading(true);
        axios
            .get("/api/Participant/SendCertificateToAllWhatsApp")
            .then((response) => {
                setIsLoading(false);
                return toast.current.show({ severity: "success", summary: "Success", detail: "Certificate Sent Successfully", life: 3000 });
            })
            .catch((error) => {
                setIsLoading(false);
                toast.current.show({ severity: "error", summary: "Error", detail: "Oops! Something Went Wrong!", life: 3000 });
            });
    };


    const getFilterListsData = () => {
        setIsLoading(true);
        axios
            .get("/api/Participant/GetAllFilterListData")
            .then((Response) => {
                const separatedLists = Response.data.reduce((acc, item) => {
                    // Create a new array for each type if it doesn't exist
                    if (!acc[item.type]) {
                        acc[item.type] = [];
                    }
                    // Push the formatted item to the respective array
                    acc[item.type].push({ name: item.name, code: item.name });
                    return acc;
                }, {});

                // Sort each list by 'name'
                Object.keys(separatedLists).forEach(type => {
                    separatedLists[type].sort((a, b) => a.name.localeCompare(b.name));
                });
                setInBatchList(separatedLists.InBatch || []);
                setOutBatchList(separatedLists.OutBatch || []);
                setTricoConfirmedList(separatedLists.TricoConfirmed || []);
                setCompanyList(separatedLists.Company || []);

                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                toast.current.show({ severity: "error", summary: "Error", detail: "Oops! Something Went Wrong!", life: 3000 });
            });
    };



    //Template for pagination
    const template2 = {
        layout: " CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown",
        CurrentPageReport: (options) => {
            return (
                <span style={{ color: "var(--text-color)", userSelect: "none", width: "19%", textAlign: "center" }}>
                    Showing {options.first} to {options.last} of {options.totalRecords} entries
                </span>
            );
        },
    };

    const removeFilter = () => {
        lazyState.current.fullName = null;
        setfullName(null);
        lazyState.current.filterHospitalName = null;
        setFilterHospitalName(null);
        lazyState.current.filterCompany = null;
        setFilterCompany(null);
        lazyState.current.filterEmail = null;
        setFilterEmail(null);
        lazyState.current.filterMobileNo = null;
        setFilterMobileNo(null);
        lazyState.current.filterCity = null;
        setFilterCity(null);
        lazyState.current.filterCertificateIssued = null;
        lazyState.current.filterSpeciality = null;
        setFilterSpeciality(null);
        lazyState.current.filterBoardingCity = null;
        setFilterBoardingCity(null);
        lazyState.current.filterState = null;
        setFilterState(null);
        lazyState.current.filterCountry = null;
        setFilterCountry(null);
        lazyState.current.filterCardNo = null;
        lazyState.current.filterAddress = null;
        setFilterAddress(null);
        setInBatchfilter(null);
        lazyState.current.inbatchfilter = null;
        setOutBatchfilter(null);
        lazyState.current.outbatchfilter = null;
        lazyState.current.TricoConfirmedfilter = null;
        lazyState.current.Designationfilter = null;
        lazyState.current.Qualificationfilter = null;
        lazyState.current.serialnumber = null;
        lazyState.current.filterPresent = null;
        lazyState.current.day2presentfilter = null;
        setSerialNumber(null);

        getparticipantlist(false, lazyState.current, null);
    };

    // Left Side Toolbar
    const leftToolbarTemplate = () => {
        return (
            <AccessControl allowedPermissions={["list:certificategiven"]}>
                <div className="my-2">
                    <Button
                        label="Print Certificate"
                        disabled={ActionDisable}
                        className="mr-2"
                        icon="fa fa-print"
                        onClick={() => {
                            handlePrint();
                        }}
                        outlined
                    ></Button>
                    {/* <Button disabled={ActionDisable} label="Certificate Issued" className="mr-2" icon="fa fa-check" onClick={() => SendCertificatetoSelectedParticipant(id)} outlined></Button> */}
                    <Button disabled={ActionDisable} label="Certificate Issued (Mail)" className={usrDetails?.role == "ITADMIN" || usrDetails?.role == "ADMIN" ? "mr-2" : "hidden"} icon="fa fa-check" onClick={() => setcertificateDialog(true)} outlined></Button>
                    <Button disabled={ActionDisable} label="Certificate Issued (WhatsApp)" className={usrDetails?.role == "ITADMIN" || usrDetails?.role == "ADMIN" ? "mr-2" : "hidden"} icon="fa fa-check" onClick={() => setcertificateWADialog(true)} outlined></Button>
                    <Button label="WhatsApp Certificate" className={usrDetails?.role == "ITADMIN" || usrDetails?.role == "ADMIN" ? "mr-2" : "hidden"} icon="fa fa-envelope" onClick={() => WhatsAppCertificatetoAll()} outlined></Button>
                    <Button label="Mail Certificate" className={usrDetails?.role == "ITADMIN" || usrDetails?.role == "ADMIN" ? "mr-2" : "hidden"} icon="fa fa-envelope" onClick={() => SendCertificatetoAll()} outlined></Button>
                </div>
            </AccessControl>
        );
    };

    // Right Side Toolbar
    const rightToolbarTemplate = () => {
        return (
            <div className="my-2">
                <AccessControl allowedPermissions={["list:certificategiven"]}>
                    <Button
                        disabled={
                            lazyState.current.fullName == null &&
                                lazyState.current.filterHospitalName == null &&
                                lazyState.current.filterCompany == null &&
                                lazyState.current.filterEmail == null &&
                                lazyState.current.filterMobileNo == null &&
                                lazyState.current.filterCity == null &&
                                lazyState.current.filterBoardingCity == null &&
                                lazyState.current.filterState == null &&
                                lazyState.current.filterCountry == null &&
                                lazyState.current.filterCardNo == null &&
                                lazyState.current.filterCertificateIssued == null &&
                                lazyState.current.inbatchfilter == null &&
                                lazyState.current.outbatchfilter == null && lazyState.current.serialnumber == null &&
                                lazyState.current.TricoConfirmedfilter == null &&
                                lazyState.current.Designationfilter == null &&
                                lazyState.current.Qualificationfilter == null &&
                                lazyState.current.filterPresent == null &&
                                lazyState.current.day2presentfilter == null
                                ? true
                                : false
                        }
                        icon="pi pi-filter-slash"
                        className="mr-2"
                        onClick={() => removeFilter()}
                        tooltip="Reset Filter"
                        tooltipOptions={{ position: "bottom", className: "showtooltip" }}
                        outlined
                    ></Button>
                    {/* {enableFilter ? <Button type="button" icon="pi pi-filter-slash" className=" mr-2 p-button-primary" onClick={(e) => setEnableFilter(false)} /> : <Button type="button" icon="pi pi-filter" className="p-button-outlined mr-2" onClick={(e) => setEnableFilter(true)} />} */}
                    <div className="my-2">{header}</div>
                </AccessControl>
                <AccessControl allowedPermissions={["export:certificategiven"]}>
                    <Button
                        disabled={!(participantlist != null && participantlist.length > 0)}
                        onClick={() => {
                            getparticipantlist(true, null);
                        }}
                        icon="pi pi-file-excel"
                        className="mr-2"
                        outlined
                    />
                </AccessControl>
            </div>
        );
    };

    //For Email
    const ButtonMailto = (rowData) => {
        return (
            <Link
                className="linkcolors"
                id="mailto"
                to="#"
                onClick={(e) => {
                    window.location.href = `mailto:${rowData.email}`;
                    e.preventDefault();
                }}
            >
                {rowData.email}
            </Link>
        );
    };

    //For Mobile No
    const ButtonPhoneto = (rowData) => {
        return (
            <Link
                className="linkcolors"
                id="callto"
                to="#"
                onClick={(e) => {
                    window.location.href = `tel:${rowData.mobileNo}`;
                    e.preventDefault();
                }}
            >
                {rowData.mobileNo}
            </Link>
        );
    };

    // For Sorting
    const onSort = (event) => {
        lazyState.current.sortField = event.sortField;
        lazyState.current.sortOrder = event.sortOrder;
        lazyState.current.first = event.first;
        lazyState.current.rows = event.rows;
        getparticipantlist(false, lazyState.current, null);
    };
    //#endregion

    //#region Method for view attendees
    const ViewAttendees = (rowData) => {
        return (
            <Link
                className="linkcolors"
                id="viewattendee"
                to="#"
                onClick={(e) => {
                    history.push("/home/viewparticipant/" + rowData.id);
                }}
            >
                {rowData.fullName}
            </Link>
        );
    };
    //#endregion

    //#region Method for filteration

    const UserFilterOnEnter = (e) => {
        if (e.key == "Enter") {
            if (e.target.value.trim() != null && e.target.value.trim() != "") {
                setIsLoading(true);
                let data = e.target.id;
                switch (data) {
                    case "fullName":
                        lazyState.current.fullName = e.target.value ? e.target.value : null;
                        break;
                    case "hospital":
                        lazyState.current.filterHospitalName = e.target.value ? e.target.value : null;
                        break;
                    case "company":
                        lazyState.current.filterCompany = e.target.value ? e.target.value : null;
                        break;
                    case "Email":
                        lazyState.current.filterEmail = e.target.value ? e.target.value : null;
                        break;
                    case "MobileNo":
                        lazyState.current.filterMobileNo = e.target.value ? e.target.value : null;
                        break;
                    case "city":
                        lazyState.current.filterCity = e.target.value ? e.target.value : null;
                        break;
                    case "address":
                        lazyState.current.filterAddress = e.target.value ? e.target.value : null;
                        break;
                    case "speciality":
                        lazyState.current.filterSpeciality = e.target.value ? e.target.value : null;
                        break;
                    case "boardingcity":
                        lazyState.current.filterBoardingCity = e.target.value ? e.target.value : null;
                        break;
                    case "state":
                        lazyState.current.filterState = e.target.value ? e.target.value : null;
                        break;
                    case "country":
                        lazyState.current.filterCountry = e.target.value ? e.target.value : null;
                        break;
                    case "inbatch":
                        lazyState.current.inbatchfilter = e.target.value ? e.target.value : null;
                        break;
                    case "outbatch":
                        lazyState.current.outbatchfilter = e.target.value ? e.target.value : null;
                        break;
                    case "serialnumber":
                        lazyState.current.serialnumber = e.target.value ? e.target.value : null;
                        break;
                }
                getparticipantlist(false, lazyState.current, null);
                setIsLoading(false);
            } else {
                setIsLoading(true);
                let data = e.target.id;
                switch (data) {
                    case "fullName":
                        lazyState.current.fullName = null;
                        break;
                    case "hospital":
                        lazyState.current.filterHospitalName = null;
                        break;
                    case "company":
                        lazyState.current.filterCompany = null;
                        break;
                    case "Email":
                        lazyState.current.filterEmail = null;
                        break;
                    case "MobileNo":
                        lazyState.current.filterMobileNo = null;
                        break;
                    case "city":
                        lazyState.current.filterCity = null;
                        break;
                    case "address":
                        lazyState.current.filterAddress = null;
                        break;
                    case "speciality":
                        lazyState.current.filterSpeciality = null;
                        break;
                    case "boardingcity":
                        lazyState.current.filterBoardingCity = null;
                        break;
                    case "state":
                        lazyState.current.filterState = null;
                        break;
                    case "country":
                        lazyState.current.filterCountry = null;
                        break;
                    case "inbatch":
                        lazyState.current.inbatchfilter = null;
                        break;
                    case "outbatch":
                        lazyState.current.outbatchfilter = null;
                        break;
                    case "serialnumber":
                        lazyState.current.serialnumber = null;
                        break;
                }
                getparticipantlist(false, lazyState.current, null);
                setIsLoading(false);
            };
        }
    };

    const InBatchFilterTemplate = () => {
        return (
            <MultiSelect
                id="inbatch"
                value={lazyState.current.inbatchfilter}
                options={inBatchList}
                itemTemplate={InBatchFilterItemTemplate}
                optionLabel="name"
                onChange={(e) => InBatchFilterClick(e)}
                placeholder="Select Any"
                className="p-column-filter"
                maxSelectedLabels={1}
                style={{ minWidth: '14rem' }}
                filter
            />
        );
    };

    const InBatchFilterItemTemplate = (option) => {
        return (
            <div className="flex align-items-center gap-2">
                <span>{option.name}</span>
            </div>
        );
    };

    const InBatchFilterBodyTemplate = (rowData) => {
        const inBatch = rowData.inBatchBooking;
        return (
            <div className="flex align-items-center gap-2">
                <span>{inBatch}</span>
            </div>
        );
    };

    const InBatchFilterClick = (e) => {
        lazyState.current.inbatchfilter = e.target.value ? e.target.value : null;
        getparticipantlist(isExportExcel, { InBatchBooking: e.value }, null);
        e.preventDefault();
    }


    const OutBatchFilterTemplate = () => {
        return (
            <MultiSelect
                id="outbatch"
                value={lazyState.current.outbatchfilter}
                options={outBatchList}
                itemTemplate={OutBatchFilterItemTemplate}
                optionLabel="name"
                onChange={(e) => OutBatchFilterClick(e)}
                placeholder="Select Any"
                className="p-column-filter"
                maxSelectedLabels={1}
                style={{ minWidth: '14rem' }}
                filter
            />
        );
    };

    const OutBatchFilterItemTemplate = (option) => {
        return (
            <div className="flex align-items-center gap-2">
                <span>{option.name}</span>
            </div>
        );
    };

    const OutBatchFilterBodyTemplate = (rowData) => {
        const outBatch = rowData.outBatchBooking;
        return (
            <div className="flex align-items-center gap-2">
                <span>{outBatch}</span>
            </div>
        );
    };

    const OutBatchFilterClick = (e) => {
        lazyState.current.outbatchfilter = e.target.value ? e.target.value : null;
        getparticipantlist(isExportExcel, { OutBatchBooking: e.value });
        e.preventDefault();
    }

    const TricoConfirmedFilterTemplate = () => {
        return (
            <MultiSelect
                value={lazyState.current.TricoConfirmedfilter}
                options={tricoConfirmedList}
                itemTemplate={TricoConfirmedFilterItemTemplate}
                optionLabel="name"
                onChange={(e) => TricoConfirmedFilterClick(e)}
                placeholder="Select Any"
                className="p-column-filter"
                maxSelectedLabels={1}
                style={{ minWidth: '14rem' }}
                filter
            />
        );
    };

    const TricoConfirmedFilterBodyTemplate = (rowData) => {
        const tricoConfirmed = rowData.tricoConfirmed;
        return (
            <div className="flex align-items-center gap-2">
                <span>{tricoConfirmed}</span>
            </div>
        );
    };
    const TricoConfirmedFilterItemTemplate = (option) => {
        return (
            <div className="flex align-items-center gap-2">
                <span>{option.name}</span>
            </div>
        );
    };

    const TricoConfirmedFilterClick = (e) => {
        lazyState.current.TricoConfirmedfilter = e.target.value ? e.target.value : null;
        getparticipantlist(isExportExcel, { TricoConfirmed: e.value });
        e.preventDefault();
    };

    const UserFilterTemplate = () => {
        return <InputText id="fullName" autoFocus value={fullName} placeholder="Enter FirstName" onChange={(e) => setfullName(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} />;
    };

    const HospitalFilterTemplate = () => {
        return <InputText id="hospital" value={filterHospitalName} placeholder="Enter Hospital" onChange={(e) => setFilterHospitalName(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} />;
    };

    const EmailFilterTemplate = () => {
        return <InputText id="Email" value={filterEmail} placeholder="Enter Email" onChange={(e) => setFilterEmail(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} />;
    };

    const MobileFilterTemplate = () => {
        return <InputText id="MobileNo" value={filterMobileNo} placeholder="Enter Mobile no" onChange={(e) => setFilterMobileNo(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} />;
    };

    // const CompanyFilterTemplate = () => {
    //     return <InputText id="company" value={filterCompany} placeholder="Enter Company" onChange={(e) => setFilterCompany(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} />;
    // };
 const CompanyFilterTemplate = () => {
        return (
            <MultiSelect
                value={lazyState.current.CompanyFilter}
                options={companyList}
                itemTemplate={CompanyFilterItemTemplate}
                optionLabel="name"
                onChange={(e) => CompanyFilterClick(e)}
                placeholder="Select Any"
                className="p-column-filter"
                maxSelectedLabels={1}
                style={{ minWidth: '14rem' }}
                filter
            />
        );
    };

    const CompanyFilterItemTemplate = (option) => {
        return (
            <div className="flex align-items-center gap-2">
                <span>{option.name}</span>
            </div>
        );
    };

    const CompanyFilterBodyTemplate = (rowData) => {
        const company = rowData.company;
        return (
            <div className="flex align-items-center gap-2">
                <span>{company}</span>
            </div>
        );
    };

    const CompanyFilterClick = (e) => {
        lazyState.current.CompanyFilter = e.target.value ? e.target.value : null;
        getparticipantlist(isExportExcel, { CompanyFilter: e.value });
        e.preventDefault();
    }

    const Day1PresentFilterTemplate = () => {
            return (
                <Dropdown
                    value={lazyState.current.filterPresent}
                    options={GlobalEnum.GlobalEnum}
                    optionLabel="name"
                    showClear
                    onChange={(e) => {
                        lazyState.current.filterPresent = e.target.value ? e.target.value : null;
                        getparticipantlist(false,{ Present: e.value });
                        e.preventDefault();
                    }}
                    placeholder="Select Arrived Status"
                    className="p-column-filter"
                />
            );
        };
    
        const Day2PresentFilterTemplate = () => {
            return (
                <Dropdown
                    value={lazyState.current.day2presentfilter}
                    options={GlobalEnum.GlobalEnum}
                    optionLabel="name"
                    showClear
                    onChange={(e) => {
                        lazyState.current.day2presentfilter = e.target.value ? e.target.value : null;
                        getparticipantlist(false,{ DayTwoPresent: e.value });
                        e.preventDefault();
                    }}
                    placeholder="Select Arrived Status"
                    className="p-column-filter"
                />
            );
        };
    const CityFilterTemplate = () => {
        return <InputText id="city" value={filterCity} placeholder="Enter City" onChange={(e) => setFilterCity(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} />;
    };

    const SerialNumberFilterTemplate = () => {
        return <InputText id="serialnumber" value={SerialNumber} placeholder="Sr No." onChange={(e) => setSerialNumber(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "5rem" }} />;
    };

     const DesignationFilterTemplate = () => {
                return (
                    <MultiSelect
                        value={lazyState.current.Designationfilter}
                        options={Designations.Designation}
                        itemTemplate={DesignationFilterItemTemplate}
                        optionLabel="name"
                        onChange={(e) => DesignationFilterClick(e)}
                        placeholder="Select Any"
                        className="p-column-filter"
                        maxSelectedLabels={1}
                        style={{ minWidth: '14rem' }}
                        filter
                    />
                );
            };
        
        const DesignationFilterItemTemplate = (option) => {
                return (
                    <div className="flex align-items-center gap-2">
                        <span>{option.name}</span>
                    </div>
                );
            };
        
        const DesignationFilterBodyTemplate = (rowData) => {
                const designation = rowData.designation;
                return (
                    <div className="flex align-items-center gap-2">
                        <span>{designation}</span>
                    </div>
                );
            };
        
        const DesignationFilterClick = (e) => {
                lazyState.current.Designationfilter = e.target.value ? e.target.value : null;
                getparticipantlist(false, { Designation: e.value });
                e.preventDefault();
            }
        
        const QualificationFilterTemplate = () => {
                return (
                    <MultiSelect
                        value={lazyState.current.Qualificationfilter}
                        options={Qualifications.Qualification}
                        itemTemplate={QualificationFilterItemTemplate}
                        optionLabel="name"
                        onChange={(e) => QualificationFilterClick(e)}
                        placeholder="Select Any"
                        className="p-column-filter"
                        maxSelectedLabels={1}
                        style={{ minWidth: '14rem' }}
                        filter
                    />
                );
            };
        
        const QualificationFilterItemTemplate = (option) => {
                return (
                    <div className="flex align-items-center gap-2">
                        <span>{option.name}</span>
                    </div>
                );
            };
        
        const QualificationFilterBodyTemplate = (rowData) => {
                const qualification = rowData.qualification;
                return (
                    <div className="flex align-items-center gap-2">
                        <span>{qualification}</span>
                    </div>
                );
            };
        
        const QualificationFilterClick = (e) => {
                lazyState.current.Qualificationfilter = e.target.value ? e.target.value : null;
                getparticipantlist(false, { Qualification: e.value });
                e.preventDefault();
            }
    

    const AddressFilterTemplate = () => {
        return <InputText id="address" value={filterAddress} placeholder="Enter Address" onChange={(e) => setFilterAddress(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} />;
    };

    const CertificateIssuedFilterTemplate = () => {
        return (
            <Dropdown
                value={lazyState.current.filterCertificateIssued}
                options={GlobalEnumData.GlobalEnum}
                optionLabel="name"
                showClear
                onChange={(e) => {
                    lazyState.current.filterCertificateIssued = e.target.value ? e.target.value : null;
                    getparticipantlist();
                    e.preventDefault();
                }}
                placeholder="Certificate Issued"
                className="p-column-filter"
            />
        );
    };

    const SpecialityFilterTemplate = () => {
        return <InputText id="speciality" value={filterSpeciality} placeholder="Enter Speciality" onChange={(e) => setFilterSpeciality(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} />;
    };

    const BoardingCityFilterTemplate = () => {
        return <InputText id="boardingcity" value={filterBoardingCity} placeholder="Enter Boarding City" onChange={(e) => setFilterBoardingCity(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} />;
    };

    const StateFilterTemplate = () => {
        return <InputText id="state" value={filterState} placeholder="Enter State" onChange={(e) => setFilterState(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} />;
    };

    const CountryFilterTemplate = () => {
        return <InputText id="country" value={filterCountry} placeholder="Enter Country" onChange={(e) => setFilterCountry(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} />;
    };
    //#endregion

    //#region Methods for screen wise

    //#region Certificate Given
    //Insert Certificate Issued Or Not
    const InsertCertificateIssued = () => {
        setIsLoading(true);
        axios
            .put("/api/Participant/updateparticipantbymultiple", {
                Id: id.toString(),
                CertificateIssued: true,
                ModifiedBy: UserName,
            })
            .then((res) => {
                setIsLoading(false);
                setid([]);
                id.splice(0, id.length);
                setSelectedParticipant([]);
                history.push("/home/dayofevent/certificategiven");
                getparticipantlist(false, lazyState.current, null);
                toast.current.show({ severity: "success", summary: "Successful", detail: "Certificate Issued", life: 3000 });
            })
            .catch((error) => {
                setIsLoading(false);
                toast.current.show({ severity: "error", summary: "Error", detail: "Oops! Something Went Wrong!", life: 3000 });
            });
    };
    //#endregion

    //#region Certification Given and Merchandise
    // Row Selection / Unselect Functions
    const onAllRowSelect = (e) => {
        setActionDisable(false);
        setid(
            e.data.map((i, k) => {
                return i.id;
            })
        );
        // setname(e.data.map((i, k) => {
        //     return i.fullName
        // }))
        setcertificatedata(e.data);
    };

    const onRowSelect = (event) => {
        setActionDisable(false);
        setid((ing) => [...ing, event.data.id]);
        setcertificatedata((ing) => [...ing, event.data]);
    };

    const onRowUnselect = (event) => {
        if (id != undefined) {
            var removeidfromdata = id.indexOf(event.data.id);
            id.splice(removeidfromdata, 1);
            if (id.length == 0) {
                setActionDisable(true);
            }
        } else {
            var removefromarray = id.indexOf(event.data.id);
            id.splice(removefromarray, 1);
        }
        if (certificatedata != undefined) {
            var removenamefromdata = certificatedata.indexOf(event.data);
            certificatedata.splice(removenamefromdata, 1);
            if (certificatedata.length == 0) {
                setActionDisable(true);
            }
        } else {
            var removefromarray = certificatedata.indexOf(event.data);
            certificatedata.splice(removefromarray, 1);
        }
    };

    const onAllRowUnSelect = (e) => {
        setActionDisable(true);
        setid(
            e.data.map((i, k) => {
                return i.id;
            })
        );
        id.splice(0, id.length);
        setcertificatedata(e.data);
        certificatedata.splice(0, certificatedata.length);
    };
    //#endregion

    //#endregion

    //#region Method for Column toggle
    const hidecolumns = [
        // { field: "speciality", header: "Speciality" },
        { field: "hospitalName", header: "Hospital" },
        { field: "countryname", header: "Country" },
        { field: "statename", header: "State" },
        { field: "cityname", header: "City" },
        { field: "boardingcityname", header: "Boarding City" },
    ];

    let unSelectedColumns = [];
    const onColumnToggle = (event) => {
        let selectedColumns = event.value;
        let orderedSelectedColumns = hidecolumns.filter((col) => selectedColumns.some((sCol) => sCol.field === col.field));
        unSelectedColumns = hidecolumns.filter((o) => !selectedColumns.some((i) => i.field === o.field && i.header === o.header));
        unselectedColumnData();
        setSelectedColumns(orderedSelectedColumns);
    };

    const unselectedColumnData = () => {
        let isFiltered = false;
        if (unSelectedColumns) {
            unSelectedColumns.map((col) => {
                switch (col.header) {
                    case "Hospital":
                        if (lazyState.current.filterHospitalName != null) {
                            lazyState.current.filterHospitalName = null;
                            isFiltered = true;
                            return setFilterHospitalName(null);
                        }
                        break;
                    case "City":
                        if (lazyState.current.filterCity != null) {
                            lazyState.current.filterCity = null;
                            isFiltered = true;
                            return setFilterCity(null);
                        }
                        break;
                    // case "Speciality":
                    //     if (lazyState.current.filterSpeciality != null) {
                    //         lazyState.current.filterSpeciality = null;
                    //         isFiltered = true;
                    //         return setFilterSpeciality(null);
                    //     }
                    //     break;
                    case "Boarding City":
                        if (lazyState.current.filterBoardingCity != null) {
                            lazyState.current.filterBoardingCity = null;
                            isFiltered = true;
                            return setFilterBoardingCity(null);
                        }
                        break;
                    case "State":
                        if (lazyState.current.filterState != null) {
                            lazyState.current.filterState = null;
                            isFiltered = true;
                            return setFilterState(null);
                        }
                        break;
                    case "Country":
                        if (lazyState.current.filterCountry != null) {
                            lazyState.current.filterCountry = null;
                            isFiltered = true;
                            return setFilterCountry(null);
                        }
                        break;
                }
            });
            if (isFiltered) {
                getparticipantlist(false, lazyState.current, null);
            }
        }
    };

    const header = (
        <div style={{ textAlign: "left", marginRight: "10px" }}>
            <MultiSelect value={selectedColumns} options={hidecolumns} optionLabel="header" placeholder="More Columns" onChange={onColumnToggle} style={{ width: "20em" }} display="chip" />
        </div>
    );

    const sortColumn = {
        Speciality: "Speciality",
        Hospital: "HospitalName",
    };

    // const hideColumnsFilterTemplate = (column) => {

    //     switch (column) {
    //         case "Designation":
    //             return DesignationFilterTemplate;
    //         }
    // }

    const hidecolumnComponents = selectedColumns.map((col) => {
        return (
            <Column
                key={col.field}
                field={col.field}
                header={col.header}
                sortable
                sortField={sortColumn[col.header]}
                headerStyle={{ width: "15%", minWidth: "15rem" }}
                filter
                showFilterMenu={false}
                filterElement={
                    col.header == "Hospital"
                        ? HospitalFilterTemplate
                        : col.header == "City"
                        ? CityFilterTemplate
                        : col.header == "Boarding City"
                        ? BoardingCityFilterTemplate
                        : col.header == "State"
                        ? StateFilterTemplate
                        : CountryFilterTemplate
                }
            />
        );
    });

    const ViewAttendeeIcon = (rowData) => {
        return (
            <div className="actions">
                <Link to="#" onClick={(e) => history.push({ pathname: "/home/viewattendeeRecord/" + rowData.id, state: { detail: "DayOfEvent" } })}>
                    {" "}
                    <span className={usrDetails?.role == "ADMIN" || usrDetails?.role == "ITADMIN" ? "fa fa-solid fa-eye" : "hidden"} id="viewattendeerecord" style={{ color: " rgb(42, 102, 151)" }}></span>
                </Link>
            </div>
        );
    };

    // On Hide Delete Dialog
    const hideCertificateDialog = () => {
        // document.documentElement.style.setProperty('overflow', 'auto')
        setcertificateDialog(false);
    };

    const hideCertificateWADialog = () => {
        // document.documentElement.style.setProperty('overflow', 'auto')
        setcertificateWADialog(false);
    };

    // Delete Confirmation Button
    const deleteProductDialogFooter = (
        <>
            <Button label="Yes" icon="fa fa-check" onClick={() => SendCertificatetoSelectedParticipant(id)} outlined />
            <Button label="No" autoFocus icon="fa fa-times" onClick={() => hideCertificateDialog(false)} outlined />
        </>
    );

    // WhatsApp Confirmation Button
    const whatsAppDialogFooter = (
        <>
            <Button label="Yes" icon="fa fa-check" onClick={() => SendCertificatetoSelectedParticipantWhatsApp(id)} outlined />
            <Button label="No" autoFocus icon="fa fa-times" onClick={() => hideCertificateWADialog(false)} outlined />
        </>
    );

    //#endregion

    return (
        <AccessControl allowedPermissions={["route:certificategiven"]} renderNoAccess={() => <NoAccess />}>
            <div className="trico-Grid">
                <div style={{ display: "none" }}>
                    {/* <div> */}
                    <div ref={componentRef}>
                        {certificatedata.map((element) => {
                            return <PrintCertificate name={element.fullName} designation={element.designation} delegateurl={delegateurl} facultyurl={facultyurl} />;
                        })}
                    </div>
                </div>
                <Toast ref={toast} />
                {isLoading ? (
                    <LoadingSpinner />
                ) : (
                    <>
                        <div className="p-col">
                            <div className="card">
                                <h5 className="headerfont">
                                    <span>
                                        <i className="fa fa-solid fa-calendar mr-2"></i>
                                        {dayOfEventScreen}
                                    </span>
                                </h5>
                                <Toolbar left={leftToolbarTemplate} right={rightToolbarTemplate} className="mb-3"></Toolbar>
                                <Tooltip target="#viewattendee" content="View / Edit Attendee" />
                                <Tooltip target="#mailto" content="Mail To" />
                                <Tooltip target="#callto" content="Call To" />
                                <Tooltip target="#viewattendeerecord" content="View Attendee Record" />
                                <DataTable
                                    size="small"
                                    stripedRows
                                    ref={dt}
                                    value={participantlist}
                                    filterDisplay={"row"}
                                    selectionMode={"checkbox"}
                                    onAllRowsSelect={onAllRowSelect}
                                    scrollHeight="calc(100vh - 250px)"
                                    lazy
                                    rows={lazyState.current.rows}
                                    onSort={onSort}
                                    sortField={lazyState.current.sortField}
                                    sortOrder={lazyState.current.sortOrder}
                                    removableSort
                                    selection={selectedParticipant}
                                    onSelectionChange={(e) => setSelectedParticipant(e.value)}
                                    onRowSelect={onRowSelect}
                                    onRowUnselect={onRowUnselect}
                                    onAllRowsUnselect={onAllRowUnSelect}
                                    dataKey="id"
                                    className="datatable-responsive"
                                    responsiveLayout="scroll"
                                    scrollable
                                >
                                    <Column body={ViewAttendeeIcon} frozen exportable={true} headerStyle={{ width: "0%", minWidth: "2rem" }}></Column>
                                    <Column frozen selectionMode="multiple" headerStyle={{ width: "0%", minWidth: "1rem" }}></Column>
                                    <Column alignFrozen="left" field="serialNumber" header="Id" sortField="SerialNumber" sortable frozen headerStyle={{ width: "3%", minWidth: "5rem" }} filter showFilterMenu={false} filterElement={SerialNumberFilterTemplate} showClearButton></Column>
                                    <Column body={ViewAttendees} sortField="FullName" frozen field="fullName" header="Name" sortable headerStyle={{ width: "15%", minWidth: "15rem" }} filter showFilterMenu={false} filterElement={UserFilterTemplate}></Column>
                                    <Column field="inBatchBooking" header="In Batch" sortField="InBatchBooking" sortable headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} filter showFilterMenu={false} filterElement={InBatchFilterTemplate} body={InBatchFilterBodyTemplate} filterField="InBatchBooking" filterMenuStyle={{ width: '14rem' }}></Column>
                                    <Column field="outBatchBooking" sortField="OutBatchBooking" sortable header="Out Batch" headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} filter showFilterMenu={false} filterElement={OutBatchFilterTemplate} body={OutBatchFilterBodyTemplate} filterField="OutBatchBooking" filterMenuStyle={{ width: '14rem' }}></Column>
                                    <Column field="tricoConfirmed" sortField="TricoConfirmed" sortable header="Trico Confirmed" headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} filter showFilterMenu={false} filterElement={TricoConfirmedFilterTemplate} body={TricoConfirmedFilterBodyTemplate} filterField="tricoConfirmed" filterMenuStyle={{ width: '14rem' }} ></Column>
                                    <Column field="designation" header="Designation" sortField="Designation" sortable filter headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ minWidth: '14rem', overflowWrap: "anywhere" }} showFilterMenu={false} filterElement={DesignationFilterTemplate} body={DesignationFilterBodyTemplate} filterField="designation" filterMenuStyle={{ width: '14rem' }}></Column>
                                    <Column field="qualification" header="Qualification" sortField="Qualification" sortable filter headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ minWidth: '14rem', overflowWrap: "anywhere" }} showFilterMenu={false} filterElement={QualificationFilterTemplate} body={QualificationFilterBodyTemplate} filterField="qualification" filterMenuStyle={{ width: '14rem' }}></Column>
                                    <Column field="company" header="Company" sortField="company" sortable headerStyle={{ width: "25%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} filter showFilterMenu={false} filterElement={CompanyFilterTemplate} body={CompanyFilterBodyTemplate} filterField="Company" filterMenuStyle={{ width: '14rem' }} ></Column>
                                    <Column body={ButtonMailto} header="Email" sortField="Email" sortable headerStyle={{ width: "15%", minWidth: "15rem" }} filter showFilterMenu={false} filterElement={EmailFilterTemplate}></Column>
                                    <Column field="presentYesNo" sortField="PresentYesNo" sortable header="Day-1 Present" headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} filter showFilterMenu={false} filterElement={Day1PresentFilterTemplate}></Column>
                                    <Column field="dayTwoPresentYesNo" sortField="DayTwoPresentYesNo" sortable header="Day-2 Present" headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} filter showFilterMenu={false} filterElement={Day2PresentFilterTemplate}></Column>
                                    <Column body={ButtonPhoneto} header="Mobile No" sortField="MobileNo" sortable headerStyle={{ width: "15%", minWidth: "15rem" }} filter showFilterMenu={false} filterElement={MobileFilterTemplate}></Column>
                                    {/* <Column field="hospitalName" header="Hospital" sortField="HospitalName" sortable headerStyle={{ width: '15%', minWidth: '15rem' }} filter showFilterMenu={false} filterElement={HospitalFilterTemplate} ></Column> */}
                                    {/* <Column field="cityname" header="City" sortField="cityname" sortable headerStyle={{ width: '15%', minWidth: '15rem' }} filter showFilterMenu={false} filterElement={CityFilterTemplate}></Column> */}
                                    {/* <Column field="address" header="Address" sortField="Address" sortable headerStyle={{ width: '15%', minWidth: '15rem' }} filter showFilterMenu={false} filterElement={AddressFilterTemplate}></Column> */}
                                    <Column field="certificateIssued" header="Certificate Issue" sortField="CertificateIssued" sortable headerStyle={{ width: "15%", minWidth: "15rem" }} filter showFilterMenu={false} filterElement={CertificateIssuedFilterTemplate}></Column>
                                    {hidecolumnComponents}
                                </DataTable>
                                <Paginator
                                    className="justify-content-end"
                                    style={{ marginRight: "15px" }}
                                    first={lazyState.current.first}
                                    rows={lazyState.current.rows}
                                    totalRecords={totalRecords}
                                    template={template2}
                                    rowsPerPageOptions={RowsPerPageDropdown}
                                    onPageChange={onBasicPageChange}
                                ></Paginator>

                                <Dialog visible={certificateDialog} draggable={false} style={{ width: "450px" }} header="Confirm" modal footer={deleteProductDialogFooter} onHide={hideCertificateDialog}>
                                    <div className="flex align-items-center">
                                        <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                                        <span>Are you sure you want to send certificate to selected attendees? </span>
                                    </div>
                                </Dialog>

                                <Dialog visible={certificateWADialog} draggable={false} style={{ width: "450px" }} header="Confirm" modal footer={whatsAppDialogFooter} onHide={hideCertificateWADialog}>
                                    <div className="flex align-items-center">
                                        <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                                        <span>Are you sure you want to send certificate on WhatsApp to selected attendees? </span>
                                    </div>
                                </Dialog>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </AccessControl>
    );
};
export default CertificateGiven;
