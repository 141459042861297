import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import axios from "axios";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Paginator } from "primereact/paginator";
import { Link } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import * as Designations from "../../enums/Designations";
import * as Qualifications from "../../enums/Qualifications";
import * as Occupancys from "../../enums/Occupancy";
import * as Travelmode from "./../../enums/Travelmodes";
import * as Gender from "./../../enums/Genders";
import * as ArrivalDate from "./../../enums/ArrivalDate";
import LoadingSpinner from "../Spinner/LoadingSpinner";
import { useHistory } from "react-router-dom";
import { Dialog } from "primereact/dialog";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import exportData from "../../services/exportToExcelService";
import { MultiSelect } from "primereact/multiselect";
import AccessControl from "../AccessControl";
import NoAccess from "../NoAccess";
import userDetails from "../../services/userTokenDetails";
import { RowsPerPageDropdown } from "../../enums/RowsPerPageOptions";
import { Tooltip } from "primereact/tooltip";
import moment from "moment";

let emptyhotel = {
    participantid: "",
    hotelname: "",
    hotelid: "",
    occupancy: "",
    occupancycode: null,
    sglroomremaining: "",
    dlbroomremaining: "",
};

let emptyhotelRooms = {
    sglroomremaining: "",
    dlbroomremaining: "",
};

const HotelAllocation = () => {
    // Hooks
    const [participantlist, setParticipantlist] = useState([]);
    const [participanthotel, setParticipanthotel] = useState(emptyhotel);
    const [HotelroomDetails, setHotelroomDetails] = useState(emptyhotelRooms);
    const [totalRecords, setTotalRecords] = useState(0);
    const [fullName, setfullName] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [hotel, sethotel] = useState([]);
    const [HotelDialog, setHotelDialog] = useState(false);
    const [ActionDisable, setActionDisable] = useState(true);
    const [id, setid] = useState(new Array());
    const [selectedParticipant, setSelectedParticipant] = useState(null);
    const [occupancydisable, setOccupancydisable] = useState(false);
    const [occupancyoptions, setoccupancyoptions] = useState([]);
    const [selectedColumns, setSelectedColumns] = useState([]);
    const [filterEmail, setFilterEmail] = useState(null);
    const [filterMobileNo, setFilterMobileNo] = useState(null);
    const [filterCompany, setFilterCompany] = useState(null);
    const [filterBoardingCity, setFilterBoardingCity] = useState(null);
    const [filterHospitalName, setFilterHospitalName] = useState(null);
    const [filterCity, setFilterCity] = useState(null);
    const [filterState, setFilterState] = useState(null);
    const [filterCountry, setFilterCountry] = useState(null);
    const [InBatchfilter, setInBatchfilter] = useState(null);
    const [OutBatchfilter, setOutBatchfilter] = useState(null);
    const [SerialNumber, setSerialNumber] = useState(null);
    const [DepartureDates,setDepartureDates] = useState(null);
    const [inBatchList, setInBatchList] = useState([]);
    const [outBatchList, setOutBatchList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [boardingCityList, setBoardingCityList] = useState([]);
    const [companyList,setCompanyList] = useState([]);
    // Flags for show/hide columns
    const [enableFilter, setEnableFilter] = useState(false);
    const [tricoConfirmedList, setTricoConfirmedList] = useState([]);

    // Const
    const toast = useRef(null);
    const dt = useRef(null);
    const history = useHistory();
    const date = new Date();
    const UserName = localStorage.getItem("Username");
    var usrDetails = userDetails();
    var company = localStorage.getItem("Company");
    const CompanyId = localStorage.getItem("CompanyId");
    let orderedSelectedColumns = [];
    let unSelectedColumns = [];

    const lazyState = useRef({
        first: 0,
        rows: RowsPerPageDropdown[0],
        page: 1,
        sortField: null,
        sortOrder: null,
        filterDesignation: null,
        filterGender: null,
        filtertoeventcity: null,
        filterfromeventcity: null,
        FullName: null,
        hospitalName: null,
        Email: null,
        mobileNo: null,
        Company: null,
        BoardingCity: null,
        City: null,
        State: null,
        Country: null,
        inbatchfilter: null,
        outbatchfilter: null,
        TricoConfirmedfilter:null,
        ArrivalTimefilter: null,
        DepartureTimefilter: null,
        Designationfilter: null,
        Qualificationfilter:null
    }); // For Sorting And Pagination Purpose

    // On Screen Load
    useEffect(() => {
        getparticipantlist();
        getData();
        getFilterListsData();
    }, []);

    //#region Common Methods

    // Get All Participants
    const getparticipantlist = (event, isExportExcel = false) => {
         //Designation
         var listDesignationFilterString = null;
         if (lazyState.current.Designationfilter != null && lazyState.current.Designationfilter != undefined && lazyState.current.Designationfilter != '') {
             if (lazyState.current.Designationfilter.length > 0) {
                 if (lazyState.current.Designationfilter.length > 1) {
                     listDesignationFilterString = lazyState.current.Designationfilter.map(item => item.name).join(',');
                 } else {
                     listDesignationFilterString = lazyState.current.Designationfilter.map(item => item.name)[0];
                 }
             }
         }
 
         //Qualification
          var listQualificationFilterString = null;
          if (lazyState.current.Qualificationfilter != null && lazyState.current.Qualificationfilter != undefined && lazyState.current.Qualificationfilter != '') {
              if (lazyState.current.Qualificationfilter.length > 0) {
                  if (lazyState.current.Qualificationfilter.length > 1) {
                     listQualificationFilterString = lazyState.current.Qualificationfilter.map(item => item.name).join(',');
                  } else {
                     listQualificationFilterString = lazyState.current.Qualificationfilter.map(item => item.name)[0];
                  }
              }
          }
       
        //In Booking Batch
        var listInBatchFilterString = null;
        if (lazyState.current.inbatchfilter != null && lazyState.current.inbatchfilter != undefined && lazyState.current.inbatchfilter != '') {
            if (lazyState.current.inbatchfilter.length > 0) {
                if (lazyState.current.inbatchfilter.length > 1) {
                    listInBatchFilterString = lazyState.current.inbatchfilter.map(item => item.name).join(',');
                } else {
                    listInBatchFilterString = lazyState.current.inbatchfilter.map(item => item.name)[0];
                }
            }
        }

        //Out Booking Batch
        var listOutBatchFilterString = null;
        if (lazyState.current.outbatchfilter != null && lazyState.current.outbatchfilter != undefined && lazyState.current.outbatchfilter != '') {
            if (lazyState.current.outbatchfilter.length > 0) {
                if (lazyState.current.outbatchfilter.length > 1) {
                    listOutBatchFilterString = lazyState.current.outbatchfilter.map(item => item.name).join(',');
                } else {
                    listOutBatchFilterString = lazyState.current.outbatchfilter.map(item => item.name)[0];
                }
            }
        }

        //Trico Confirmed Batch
        var listTricoConfirmedFilterString = null;
        if (lazyState.current.TricoConfirmedfilter != null && lazyState.current.TricoConfirmedfilter != undefined && lazyState.current.TricoConfirmedfilter != '') {
            if (lazyState.current.TricoConfirmedfilter.length > 0) {
                if (lazyState.current.TricoConfirmedfilter.length > 1) {
                    listTricoConfirmedFilterString = lazyState.current.TricoConfirmedfilter.map(item => item.name).join(',');
                } else {
                    listTricoConfirmedFilterString = lazyState.current.TricoConfirmedfilter.map(item => item.name)[0];
                }
            }
        }
       //Departure Time
        var listDepartureTimeFilterString = null;
        if (lazyState.current.DepartureTimefilter != null && lazyState.current.DepartureTimefilter != undefined && lazyState.current.DepartureTimefilter != '') {
            if (lazyState.current.DepartureTimefilter.length > 0) {
                if (lazyState.current.DepartureTimefilter.length > 1) {
                    listDepartureTimeFilterString = lazyState.current.DepartureTimefilter.map(item => item.code).join(',');
                } else {
                    listDepartureTimeFilterString = lazyState.current.DepartureTimefilter.map(item => item.code)[0];
                }
            }
        }
        //Arrival Time
        var listArrivalTimeFilterString = null;
        if (lazyState.current.ArrivalTimefilter != null && lazyState.current.ArrivalTimefilter != undefined && lazyState.current.ArrivalTimefilter != '') {
            if (lazyState.current.ArrivalTimefilter.length > 0) {
                if (lazyState.current.ArrivalTimefilter.length > 1) {
                    listArrivalTimeFilterString = lazyState.current.ArrivalTimefilter.map(item => item.code).join(',');
                } else {
                    listArrivalTimeFilterString = lazyState.current.ArrivalTimefilter.map(item => item.code)[0];
                }
            }
        }
         //City
         var listCityFilterString = null;
         if (lazyState.current.CityFilter != null && lazyState.current.CityFilter != undefined && lazyState.current.CityFilter != '') {
             if (lazyState.current.CityFilter.length > 0) {
                 if (lazyState.current.CityFilter.length > 1) {
                     listCityFilterString = lazyState.current.CityFilter.map(item => item.name).join(',');
                 } else {
                     listCityFilterString = lazyState.current.CityFilter.map(item => item.name)[0];
                 }
             }
         }
 
         //Boarding City
         var listBoardingCityFilterString = null;
         if (lazyState.current.BoardingCityFilter != null && lazyState.current.BoardingCityFilter != undefined && lazyState.current.BoardingCityFilter != '') {
             if (lazyState.current.BoardingCityFilter.length > 0) {
                 if (lazyState.current.BoardingCityFilter.length > 1) {
                     listBoardingCityFilterString = lazyState.current.BoardingCityFilter.map(item => item.name).join(',');
                 } else {
                     listBoardingCityFilterString = lazyState.current.BoardingCityFilter.map(item => item.name)[0];
                 }
             }
         }

         
        //Company
        var listCompanyFilterString = null;
        if (lazyState.current.CompanyFilter != null && lazyState.current.CompanyFilter != undefined && lazyState.current.CompanyFilter != '') {
            if (lazyState.current.CompanyFilter.length > 0) {
                if (lazyState.current.CompanyFilter.length > 1) {
                    listCompanyFilterString = lazyState.current.CompanyFilter.map(item => item.name).join(',');
                } else {
                    listCompanyFilterString = lazyState.current.CompanyFilter.map(item => item.name)[0];
                }
            }
        }
 
        setIsLoading(true);
        if (!isExportExcel) setParticipantlist([]);
        axios
            .post("/api/Participant/GetAll", {
                paginationRequired: !isExportExcel,
                SortDirection: lazyState.current.sortOrder,
                PageSize: lazyState.current.rows,
                CurrentPageNumber: event && event.rows ? event.first / event.rows + 1 : lazyState.current.page,
                SortField: event && event.sortField ? event.sortField : "Firstname",
                registrationStatus: "APPROVED",
                CompanyWiseFilter: usrDetails.role === "MR" || usrDetails.role === "SUBMR" ? CompanyId : null, // For Mr & Sub Mr Login
                FullName: lazyState.current.FullName ? lazyState.current.FullName.trim() : null,
                HospitalName: lazyState.current.hospitalName ? lazyState.current.hospitalName.trim() : null,
                Email: lazyState.current.Email ? lazyState.current.Email.trim() : null,
                MobileNo: lazyState.current.mobileNo ? lazyState.current.mobileNo.trim() : null,
                CompanyFilter: listCompanyFilterString ? listCompanyFilterString : null,
                City: listCityFilterString ? listCityFilterString : null,
                BoardingCity: listBoardingCityFilterString ? listBoardingCityFilterString : null,
                State: lazyState.current.State ? lazyState.current.State.trim() : null,
                Country: lazyState.current.Country ? lazyState.current.Country.trim() : null,
                Designation: listDesignationFilterString ? listDesignationFilterString : null,
                Qualification:listQualificationFilterString ? listQualificationFilterString : null,
                Gender: lazyState.current.filterGender ? lazyState.current.filterGender.code.trim() : null,
                ToEventCity: lazyState.current.filtertoeventcity ? lazyState.current.filtertoeventcity.code.trim() : null,
                FromEventCity: lazyState.current.filterfromeventcity ? lazyState.current.filterfromeventcity.code.trim() : null,
                InBatchBooking: listInBatchFilterString ? listInBatchFilterString : null,
                OutBatchBooking: listOutBatchFilterString ? listOutBatchFilterString : null,
                TricoConfirmed: listTricoConfirmedFilterString || (listTricoConfirmedFilterString === 'Not Assigned' ? 'Not Assigned' : null),
                NeedAccomodation: true,
                ParticipantNotAllocatedHotel: true,
                ArrivalFilter:listArrivalTimeFilterString ? listArrivalTimeFilterString : null,
                Departurefilter:listDepartureTimeFilterString ? listDepartureTimeFilterString : null,
                SerialNumber: lazyState.current.serialnumber ? lazyState.current.serialnumber.trim() : null,
            })
            .then((Response) => {
                if (!isExportExcel) setParticipantlist([]);
                let tempData = [];
                if (Response.data.data != null) {
                    for (let dataObj of Response.data.data) {
                        // Pass body data in excelsheets.
                        let allExcelData;
                        let excelcolumnData = {
                            Id: dataObj.serialNumber,
                            Name: dataObj.fullName,
                            Arrival: dataObj.arrivalTime ? moment(dataObj.arrivalTime).format("DD/MM/YYYY HH:mm") : "",
                            Departure: dataObj.departureTime ? moment(dataObj.departureTime).format("DD/MM/YYYY HH:mm") : "",
                            TricoConfirmed:dataObj.tricoConfirmed,
                            InBatch: dataObj.inBatchBooking,
                            Gender:dataObj.gender,
                            Designation:dataObj.designation,
                            Qualification:dataObj.qualification,
                            City:dataObj.cityname,
                            "Boarding City":dataObj.boardingcityname,
                            Company: dataObj.company,
                            "To Event City": dataObj.travelmode_Inbound,
                            MobileNo: dataObj.mobileNo,
                            Email: dataObj.email,
                            OutBatch: dataObj.outBatchBooking,
                            // Hospital: dataObj.hospitalName,
                            
                            // "From Event City": dataObj.travelmode_Outbound,
                        };
                        if (selectedColumns.length > 0) {
                            for (let selectedColumn of selectedColumns) {
                                let fieldName = selectedColumn.field;
                                let headerName = selectedColumn.header;
                                excelcolumnData = { ...excelcolumnData, [headerName]: dataObj[fieldName] };
                            }
                            tempData.push(excelcolumnData);
                        } else {
                            allExcelData = {
                                Id: dataObj.serialNumber,
                                Name: dataObj.fullName,
                                Arrival: dataObj.arrivalTime ? moment(dataObj.arrivalTime).format("DD/MM/YYYY HH:mm") : "",
                                Departure: dataObj.departureTime ? moment(dataObj.departureTime).format("DD/MM/YYYY HH:mm") : "",
                                TricoConfirmed: dataObj.tricoConfirmed,
                                InBatch: dataObj.inBatchBooking,
                                Gender:dataObj.gender,
                                Designation:dataObj.designation,
                                Qualification:dataObj.qualification,
                                City:dataObj.cityname,
                                "Boarding City":dataObj.boardingcityname,
                                Company: dataObj.company,
                                "To Event City": dataObj.travelmode_Inbound,
                                OutBatch: dataObj.outBatchBooking,
                                MobileNo: dataObj.mobileNo,
                                Email: dataObj.email,
                                // Hospital: dataObj.hospitalName,
                                // "To Event City": dataObj.travelmode_Inbound,
                                // "From Event City": dataObj.travelmode_Outbound,
                            };
                            tempData.push(allExcelData);
                        }
                    }
                    if (isExportExcel == true) {
                        exportData(tempData, "Hotel Allocation");
                        setIsLoading(false);
                        return false;
                    }
                    console.log(Response.data.data);
                    setTotalRecords(Response.data.totalRecords);
                    setParticipantlist(Response.data.data);
                    gethotellist();
                }
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                toast.current.show({ severity: "error", summary: "Error", detail: "Oops! Something Went Wrong!", life: 3000 });
            });
    };

    const getFilterListsData = () => {
        setIsLoading(true);
        axios
            .get("/api/Participant/GetAllFilterListData")
            .then((Response) => {
                const separatedLists = Response.data.reduce((acc, item) => {
                    // Create a new array for each type if it doesn't exist
                    if (!acc[item.type]) {
                        acc[item.type] = [];
                    }
                    // Push the formatted item to the respective array
                    acc[item.type].push({ name: item.name, code: item.name });
                    return acc;
                }, {});

                // Sort each list by 'name'
                Object.keys(separatedLists).forEach(type => {
                    separatedLists[type].sort((a, b) => a.name.localeCompare(b.name));
                });
                setCityList(separatedLists.City || []);
                setBoardingCityList(separatedLists.BCity || []);
                setInBatchList(separatedLists.InBatch || []);
                setOutBatchList(separatedLists.OutBatch || []);
                setCompanyList(separatedLists.Company || [])
                setTricoConfirmedList(separatedLists.TricoConfirmed || []);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                toast.current.show({ severity: "error", summary: "Error", detail: "Oops! Something Went Wrong!", life: 3000 });
            });
    };

    // Get All Hotel Names In Dropdown
    const gethotellist = () => {
        axios
            .post("/api/hotel/GetAll", {
                paginationRequired: false,
            })
            .then((Response) => {
                let data = [];
                for (const dataObj of Response.data.data) {
                    data.push({ name: dataObj.name, code: dataObj.id });
                }
                sethotel(data);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                toast.current.show({ severity: "error", summary: "Error", detail: "Oops! Something Went Wrong!", life: 3000 });
            });
    };

    //pagination
    const onBasicPageChange = (event) => {
        // const newPage = event.first / event.rows + 1;
        // if (newPage !== lazyState.current.first / lazyState.current.rows + 1) {
        setIsLoading(true);
        lazyState.current.sortField = lazyState.current.sortField;
        lazyState.current.sortOrder = lazyState.current.sortOrder;
        lazyState.current.first = event.first;
        lazyState.current.rows = event.rows;
        getparticipantlist(lazyState.current, false);
        setIsLoading(false);
        // }
    };

    //Template for pagination
    const template2 = {
        layout: " CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown",
        CurrentPageReport: (options) => {
            return (
                <span style={{ color: "var(--text-color)", userSelect: "none", width: "19%", textAlign: "center" }}>
                    Showing {options.first} to {options.last} of {options.totalRecords} entries
                </span>
            );
        },
        RowsPerPageDropdown: (options) => {
            return (
                <span>
                    Items Per Page :<Dropdown value={options.value} options={RowsPerPageDropdown} onChange={options.onChange} />
                </span>
            );
        },
    };

    // For Sorting
    const onSort = (event) => {
        lazyState.current.sortField = event.sortField;
        lazyState.current.sortOrder = event.sortOrder;
        lazyState.current.first = event.first;
        lazyState.current.rows = event.rows;
        getparticipantlist(lazyState.current, false);
    };

    //#endregion

    //Get Selected Hotel Available Rooms By Hotel Id (Both Sgl , DBl)
    const GetSelectedHotelAvailableRooms = (hotelid) => {
        axios
            .get("/api/Participant/GetSelectedHotelAvailableRooms/" + hotelid)
            .then((Response) => {
                let _participanthotel = { ...Response.data };
                _participanthotel["sglroomremaining"] = Response.data.sglroomremaining;
                _participanthotel["dlbroomremaining"] = Response.data.dlbroomremaining;
                setHotelroomDetails(_participanthotel);
                if (Response.data.sglroomremaining > 0 || Response.data.dlbroomremaining > 0) {
                    if (Response.data.sglroomremaining > 0 && Response.data.dlbroomremaining > 0) {
                        setOccupancydisable(false);
                        setoccupancyoptions(Occupancys.Occupancy);
                    }
                    if (Response.data.sglroomremaining > 0 && Response.data.dlbroomremaining == 0) {
                        setOccupancydisable(false);
                        setoccupancyoptions(Occupancys.Occupancy.filter((x) => x.code == true));
                    }
                    if (Response.data.sglroomremaining == 0 && Response.data.dlbroomremaining > 0) {
                        setOccupancydisable(false);
                        setoccupancyoptions(Occupancys.Occupancy.filter((x) => x.code == false));
                    }
                    setOccupancydisable(false);
                } else {
                    setOccupancydisable(true);
                }
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                toast.current.show({ severity: "error", summary: "Error", detail: "Oops! Something Went Wrong!", life: 3000 });
            });
    };

    // Insert Allocate Hotel For Participant
    const InsertHotel = () => {
        if (participanthotel.hotelid.trim()) {
            if (participanthotel.occupancy.trim()) {
                setIsLoading(true);
                const ids = []; // Contain Multiple Id For Participant
                const data = [];
                const url = "/api/Participant/SaveParticipantAllocateHotelRecord";
                id.forEach((id, index) => {
                    ids.push(id);
                    data.push({
                        participantId: id,
                        hotelName: participanthotel.hotelid,
                        occupancy: participanthotel.occupancycode,
                        sharingWith: !participanthotel.occupancycode,
                        createdBy: UserName,
                        created: date,
                        modifiedBy: UserName,
                        modified: date,
                    });
                });
                axios
                    .post(url, data)
                    .then(() => {
                        id.splice(0, id.length);
                        setid([]);
                        setActionDisable(true);
                        setHotelDialog(false);
                        getparticipantlist(lazyState.current, false);
                        setSelectedParticipant([]);
                        setHotelroomDetails(emptyhotelRooms);
                        setParticipanthotel(emptyhotel);
                        setSelectedParticipant(null);
                        setIsLoading(false);
                        toast.current.show({ severity: "success", summary: "Successful", detail: "Hotel Allocated Successfully", life: 3000 });
                        document.documentElement.style.setProperty("overflow", "auto");
                        getparticipantlist(lazyState.current, false);
                        setHotelDialog(false);
                    })
                    .catch((error) => {
                        setIsLoading(false);
                        toast.current.show({ severity: "error", summary: "Error", detail: "Oops! Something Went Wrong!", life: 3000 });
                    });
            } else {
                toast.current.show({ severity: "error", summary: "Warning", detail: "Please Select Occupancy", life: 3000 });
            }
        } else {
            toast.current.show({ severity: "error", summary: "Warning", detail: "Please Select Hotel", life: 3000 });
        }
    };

    const FilterOnEnter = (e) => {
        if (e.key == "Enter") {
            if (e.target.value.trim() != null && e.target.value.trim() != "") {
                setIsLoading(true);
                let data = e.target.id;
                switch (data) {
                    case "fullName":
                        lazyState.current.FullName = e.target.value ? e.target.value : null;
                        break;
                    case "city":
                        lazyState.current.City = e.target.value ? e.target.value : null;
                        break;
                    case "state":
                        lazyState.current.State = e.target.value ? e.target.value : null;
                        break;
                    case "country":
                        lazyState.current.Country = e.target.value ? e.target.value : null;
                        break;
                    case "hospital":
                        lazyState.current.hospitalName = e.target.value ? e.target.value : null;
                        break;
                    case "company":
                        lazyState.current.Company = e.target.value ? e.target.value : null;
                        break;
                    case "Email":
                        lazyState.current.Email = e.target.value ? e.target.value : null;
                        break;
                    case "MobileNo":
                        lazyState.current.mobileNo = e.target.value ? e.target.value : null;
                        break;
                    case "boardingcity":
                        lazyState.current.BoardingCity = e.target.value ? e.target.value : null;
                        break;
                    case "inbatch":
                        lazyState.current.inbatchfilter = e.target.value ? e.target.value : null;
                        break;
                    case "outbatch":
                        lazyState.current.outbatchfilter = e.target.value ? e.target.value : null;
                        break;
                    case "serialnumber":
                        lazyState.current.serialnumber = e.target.value ? e.target.value : null;
                        break;
                }
                getparticipantlist(lazyState.current, false);
                setIsLoading(false);
            } else {
                setIsLoading(true);
                let data = e.target.id;
                switch (data) {
                    case "fullName":
                        lazyState.current.FullName = null;
                        break;
                    case "city":
                        lazyState.current.City = null;
                        break;
                    case "state":
                        lazyState.current.State = null;
                        break;
                    case "country":
                        lazyState.current.Country = null;
                        break;
                    case "hospital":
                        lazyState.current.hospitalName = null;
                        break;
                    case "company":
                        lazyState.current.Company = null;
                        break;
                    case "Email":
                        lazyState.current.Email = null;
                        break;
                    case "MobileNo":
                        lazyState.current.mobileNo = null;
                        break;
                    case "boardingcity":
                        lazyState.current.BoardingCity = null;
                        break;
                    case "inbatch":
                        lazyState.current.inbatchfilter = null;
                        break;
                    case "outbatch":
                        lazyState.current.outbatchfilter = null;
                        break;
                    case "serialnumber":
                        lazyState.current.serialnumber = null;
                        break;
                }
                getparticipantlist(lazyState.current, false);
                setIsLoading(false);
            }
        }
    };

    const SerialNumberFilterTemplate = () => {
        return <InputText id="serialnumber" value={SerialNumber} placeholder="Sr No." onChange={(e) => setSerialNumber(e.target.value)} onKeyDown={(e) => FilterOnEnter(e)} style={{ minWidth: "5rem" }} />;
    };

    const UserFilterTemplate = () => {
        return <InputText id="fullName" autoFocus value={fullName} placeholder="Enter Name" onChange={(e) => setfullName(e.target.value)} onKeyDown={(e) => FilterOnEnter(e)} style={{ width: "200px" }} />;
    };
    const DesignationFilterTemplate = () => {
            return (
                <MultiSelect
                    value={lazyState.current.Designationfilter}
                    options={Designations.Designation}
                    itemTemplate={DesignationFilterItemTemplate}
                    optionLabel="name"
                    onChange={(e) => DesignationFilterClick(e)}
                    placeholder="Select Any"
                    className="p-column-filter"
                    maxSelectedLabels={1}
                    style={{ minWidth: '14rem' }}
                    filter
                />
            );
        };
    
    const DesignationFilterItemTemplate = (option) => {
            return (
                <div className="flex align-items-center gap-2">
                    <span>{option.name}</span>
                </div>
            );
        };
    
    const DesignationFilterBodyTemplate = (rowData) => {
            const designation = rowData.designation;
            return (
                <div className="flex align-items-center gap-2">
                    <span>{designation}</span>
                </div>
            );
        };
    
    const DesignationFilterClick = (e) => {
            lazyState.current.Designationfilter = e.target.value ? e.target.value : null;
            getparticipantlist(false, { Designation: e.value });
            e.preventDefault();
        }
    
    const QualificationFilterTemplate = () => {
            return (
                <MultiSelect
                    value={lazyState.current.Qualificationfilter}
                    options={Qualifications.Qualification}
                    itemTemplate={QualificationFilterItemTemplate}
                    optionLabel="name"
                    onChange={(e) => QualificationFilterClick(e)}
                    placeholder="Select Any"
                    className="p-column-filter"
                    maxSelectedLabels={1}
                    style={{ minWidth: '14rem' }}
                    filter
                />
            );
        };
    
    const QualificationFilterItemTemplate = (option) => {
            return (
                <div className="flex align-items-center gap-2">
                    <span>{option.name}</span>
                </div>
            );
        };
    
    const QualificationFilterBodyTemplate = (rowData) => {
            const qualification = rowData.qualification;
            return (
                <div className="flex align-items-center gap-2">
                    <span>{qualification}</span>
                </div>
            );
        };
    
    const QualificationFilterClick = (e) => {
            lazyState.current.Qualificationfilter = e.target.value ? e.target.value : null;
            getparticipantlist(false, { Qualification: e.value });
            e.preventDefault();
        }

    const TricoConfirmedFilterTemplate = () => {
            return (
                <MultiSelect
                    value={lazyState.current.TricoConfirmedfilter}
                    options={tricoConfirmedList}
                    itemTemplate={TricoConfirmedFilterItemTemplate}
                    optionLabel="name"
                    onChange={(e) => TricoConfirmedFilterClick(e)}
                    placeholder="Select Any"
                    className="p-column-filter"
                    maxSelectedLabels={1}
                    style={{ minWidth: '14rem' }}
                    filter
                />
            );
        };
    
    const TricoConfirmedFilterBodyTemplate = (rowData) => {
            const tricoConfirmed = rowData.tricoConfirmed;
            return (
                <div className="flex align-items-center gap-2">
                    <span>{tricoConfirmed}</span>
                </div>
            );
        };
    const TricoConfirmedFilterItemTemplate = (option) => {
            return (
                <div className="flex align-items-center gap-2">
                    <span>{option.name}</span>
                </div>
            );
        };
    
    const TricoConfirmedFilterClick = (e) => {
            lazyState.current.TricoConfirmedfilter = e.target.value ? e.target.value : null;
            getparticipantlist(false, { TricoConfirmed: e.value });
            e.preventDefault();
        };
        

    const GenderFilterTemplate = () => {
        return (
            <Dropdown
                value={lazyState.current.filterGender}
                options={Gender.Gender}
                optionLabel="name"
                showClear
                onChange={(e) => {
                    lazyState.current.filterGender = e.target.value ? e.target.value : null;
                    getparticipantlist();
                    e.preventDefault();
                }}
                placeholder="Select Gender"
                className="p-column-filter"
                style={{ minWidth: "12rem" }}
            />
        );
    };

    const ToEventCityTemplate = () => {
        return (
            <Dropdown
                value={lazyState.current.filtertoeventcity}
                options={Travelmode.Travelmode}
                optionLabel="name"
                showClear
                onChange={(e) => {
                    lazyState.current.filtertoeventcity = e.target.value ? e.target.value : null;
                    getparticipantlist();
                    e.preventDefault();
                }}
                placeholder="Select TravelMode "
                className="p-column-filter"
                style={{ minWidth: "12rem" }}
            />
        );
    };

    const FromEventCityTemplate = () => {
        return (
            <Dropdown
                value={lazyState.current.filterfromeventcity}
                options={Travelmode.Travelmode}
                optionLabel="name"
                showClear
                onChange={(e) => {
                    lazyState.current.filterfromeventcity = e.target.value ? e.target.value : null;
                    getparticipantlist();
                    e.preventDefault();
                }}
                placeholder="Select TravelMode"
                className="p-column-filter"
                style={{ minWidth: "12rem" }}
            />
        );
    };

    const CityFilterTemplate = () => {
            return (
                <MultiSelect
                    value={lazyState.current.CityFilter}
                    options={cityList}
                    itemTemplate={CityFilterItemTemplate}
                    optionLabel="name"
                    onChange={(e) => CityFilterClick(e)}
                    placeholder="Select Any"
                    className="p-column-filter"
                    maxSelectedLabels={1}
                    style={{ minWidth: '14rem' }}
                    filter
                />
            );
        };
    
        const CityFilterItemTemplate = (option) => {
            return (
                <div className="flex align-items-center gap-2">
                    <span>{option.name}</span>
                </div>
            );
        };
    
        const CityFilterBodyTemplate = (rowData) => {
            const city = rowData.cityname;
            return (
                <div className="flex align-items-center gap-2">
                    <span>{city}</span>
                </div>
            );
        };
    
        const CityFilterClick = (e) => {
            lazyState.current.CityFilter = e.target.value ? e.target.value : null;
            getparticipantlist({ City: e.value },false);
            e.preventDefault();
        }

         const BoardingCityFilterTemplate = () => {
                return (
                    <MultiSelect
                        value={lazyState.current.BoardingCityFilter}
                        options={boardingCityList}
                        itemTemplate={BoardingCityFilterItemTemplate}
                        optionLabel="name"
                        onChange={(e) => BoardingCityFilterClick(e)}
                        placeholder="Select Any"
                        className="p-column-filter"
                        maxSelectedLabels={1}
                        style={{ minWidth: '14rem' }}
                        filter
                    />
                );
            };
        
            const BoardingCityFilterItemTemplate = (option) => {
                return (
                    <div className="flex align-items-center gap-2">
                        <span>{option.name}</span>
                    </div>
                );
            };
        
            const BoardingCityFilterBodyTemplate = (rowData) => {
                const boardingcity = rowData.boardingcityname;
                return (
                    <div className="flex align-items-center gap-2">
                        <span>{boardingcity}</span>
                    </div>
                );
            };
        
            const BoardingCityFilterClick = (e) => {
                lazyState.current.BoardingCityFilter = e.target.value ? e.target.value : null;
                getparticipantlist({ City: e.value },false);
                e.preventDefault();
            }
        
            const InBatchFilterTemplate = () => {
                return (
                    <MultiSelect
                        value={lazyState.current.inbatchfilter}
                        options={inBatchList}
                        itemTemplate={InBatchFilterItemTemplate}
                        optionLabel="name"
                        onChange={(e) => InBatchFilterClick(e)}
                        placeholder="Select Any"
                        className="p-column-filter"
                        maxSelectedLabels={1}
                        style={{ minWidth: '14rem' }}
                        filter
                    />
                );
            };
        
            const InBatchFilterItemTemplate = (option) => {
                return (
                    <div className="flex align-items-center gap-2">
                        <span>{option.name}</span>
                    </div>
                );
            };
        
            const InBatchFilterBodyTemplate = (rowData) => {
                const inBatch = rowData.inBatchBooking;
                return (
                    <div className="flex align-items-center gap-2">
                        <span>{inBatch}</span>
                    </div>
                );
            };
        
            const InBatchFilterClick = (e) => {
                lazyState.current.inbatchfilter = e.target.value ? e.target.value : null;
                getparticipantlist({ InBatch: e.value },false);
                e.preventDefault();
            }
        
        
        
            const OutBatchFilterTemplate = () => {
                return (
                    <MultiSelect
                        value={lazyState.current.outbatchfilter}
                        options={outBatchList}
                        itemTemplate={OutBatchFilterItemTemplate}
                        optionLabel="name"
                        onChange={(e) => OutBatchFilterClick(e)}
                        placeholder="Select Any"
                        className="p-column-filter"
                        maxSelectedLabels={1}
                        style={{ minWidth: '14rem' }}
                        filter
                    />
                );
            };
        
            const OutBatchFilterItemTemplate = (option) => {
                return (
                    <div className="flex align-items-center gap-2">
                        <span>{option.name}</span>
                    </div>
                );
            };
        
            const OutBatchFilterBodyTemplate = (rowData) => {
                const outBatch = rowData.outBatchBooking;
                return (
                    <div className="flex align-items-center gap-2">
                        <span>{outBatch}</span>
                    </div>
                );
            };
        
            const OutBatchFilterClick = (e) => {
                lazyState.current.outbatchfilter = e.target.value ? e.target.value : null;
                getparticipantlist({ OutBatch: e.value },false);
                e.preventDefault();
            }

    const StateTemplate = () => {
        return <InputText id="state" value={filterState} placeholder="Enter State" onChange={(e) => setFilterState(e.target.value)} onKeyDown={(e) => FilterOnEnter(e)} style={{ width: "200px" }} />;
    };

    const CountryTemplate = () => {
        return <InputText id="country" value={filterCountry} placeholder="Enter Country" onChange={(e) => setFilterCountry(e.target.value)} onKeyDown={(e) => FilterOnEnter(e)} style={{ width: "200px" }} />;
    };

    const HospitalFilterTemplate = () => {
        return <InputText id="hospital" value={filterHospitalName} placeholder="Enter Hospital" onChange={(e) => setFilterHospitalName(e.target.value)} onKeyDown={(e) => FilterOnEnter(e)} style={{ width: "200px" }} />;
    };


    const EmailFilterTemplate = () => {
        return <InputText id="Email" value={filterEmail} placeholder="Enter Email" onChange={(e) => setFilterEmail(e.target.value)} onKeyDown={(e) => FilterOnEnter(e)} />;
    };

    const MobileFilterTemplate = () => {
        return <InputText id="MobileNo" value={filterMobileNo} placeholder="Enter Mobile no" onChange={(e) => setFilterMobileNo(e.target.value)} onKeyDown={(e) => FilterOnEnter(e)} />;
    };

       const CompanyFilterTemplate = () => {
            return (
                <MultiSelect
                    value={lazyState.current.CompanyFilter}
                    options={companyList}
                    itemTemplate={CompanyFilterItemTemplate}
                    optionLabel="name"
                    onChange={(e) => CompanyFilterClick(e)}
                    placeholder="Select Any"
                    className="p-column-filter"
                    maxSelectedLabels={1}
                    style={{ minWidth: '14rem' }}
                    filter
                />
            );
        };
    
        const CompanyFilterItemTemplate = (option) => {
            return (
                <div className="flex align-items-center gap-2">
                    <span>{option.name}</span>
                </div>
            );
        };
    
        const CompanyFilterBodyTemplate = (rowData) => {
            const company = rowData.company;
            return (
                <div className="flex align-items-center gap-2">
                    <span>{company}</span>
                </div>
            );
        };
    
        const CompanyFilterClick = (e) => {
            lazyState.current.CompanyFilter = e.target.value ? e.target.value : null;
            getparticipantlist( { CompanyFilter: e.value },false);
            e.preventDefault();
        }
    //#endregion

    //For Email
    const ButtonMailto = (rowData) => {
        return (
            <Link
                className="linkcolors"
                id="mailto"
                to="#"
                onClick={(e) => {
                    window.location.href = `mailto:${rowData.email}`;
                    e.preventDefault();
                }}
            >
                {rowData.email}
            </Link>
        );
    };

    //For Mobile No
    const ButtonPhoneto = (rowData) => {
        return (
            <Link
                className="linkcolors"
                id="callto"
                to="#"
                onClick={(e) => {
                    window.location.href = `tel:${rowData.mobileNo}`;
                    e.preventDefault();
                }}
            >
                {rowData.mobileNo}
            </Link>
        );
    };

    //For view Attendee
    const ViewAttendees = (rowData) => {
        return (
            <Link
                className="linkcolors"
                id="viewattendee"
                to="#"
                onClick={(e) => {
                    history.push("/home/viewparticipant/" + rowData.id);
                }}
            >
                {rowData.fullName}
            </Link>
        );
    };

    const CancelDialog = () => {
        document.documentElement.style.setProperty("overflow", "auto");
        setIsLoading(false);
        setHotelroomDetails(emptyhotelRooms);
        setParticipanthotel(emptyhotel);
        setHotelDialog(false);
        getparticipantlist(lazyState.current, false);
    };

    // Modals Button To Add Allocate Hotel
    const HotelDialogFooter = (
        <>
            <Button label="Save" autoFocus icon="fa fa-check" onClick={() => InsertHotel()} outlined />
            <Button
                label="Cancel"
                icon="fa fa-times"
                onClick={() => {
                    CancelDialog();
                }}
                outlined
            />
        </>
    );

    // Left Side Toolbar
    const leftToolbarTemplate = () => {
        return (
            <div className="my-2">
                <>
                    <h4 className="headerfont m-auto mr-2">Count : {totalRecords}</h4>
                </>
                <AccessControl allowedPermissions={["add:participant"]}>
                    <Button
                        disabled={ActionDisable}
                        icon="fa fa-hotel"
                        label="Allocate Hotel"
                        className="mr-2"
                        onClick={() => {
                            document.documentElement.style.setProperty("overflow", "hidden");
                            setHotelDialog(true);
                        }}
                        outlined
                    />
                </AccessControl>
                <Dialog
                    visible={HotelDialog}
                    draggable={false}
                    style={{ width: "450px" }}
                    header="Allocate Hotel"
                    modal
                    className="p-fluid"
                    footer={HotelDialogFooter}
                    onHide={() => {
                        CancelDialog();
                    }}
                >
                    <div className="field">
                        <label id="label" htmlFor="hotel">
                            Hotel Name
                        </label>
                        <label id="Mandatoryfield">*</label>
                        <Dropdown filter value={participanthotel.hotelname} placeholder={participanthotel.hotelname == "" ? "Select Hotel Name" : participanthotel.hotelname} options={hotel} optionLabel="name" onChange={(e) => onDropdownChange(e, "hotelname")} />
                    </div>
                    <div className={participanthotel.hotelname == "" ? "hidden" : "field"}>
                        <span id="label" className={participanthotel.hotelname == "" ? "hidden" : ""}>
                            SGL Room Remaining: {HotelroomDetails.sglroomremaining}
                        </span>
                        <br></br>
                        <span id="label" className={participanthotel.hotelname == "" ? "hidden" : ""}>
                            DBL Room Remaining: {HotelroomDetails.dlbroomremaining}
                        </span>
                    </div>
                    <div className="field">
                        <label id="label" htmlFor="occupancy">
                            Occupancy
                        </label>
                        <label id="Mandatoryfield">*</label>
                        <Dropdown disabled={occupancydisable} value={participanthotel.occupancy} placeholder={participanthotel.occupancy == "" ? "Select Occupancy" : participanthotel.occupancy} options={occupancyoptions} optionLabel="name" onChange={(e) => onDropdownChange(e, "occupancy")} />
                    </div>
                </Dialog>
            </div>
        );
    };

    const removeFilter = () => {
        lazyState.current.FullName = null;
        setfullName(null);
        lazyState.current.hospitalName = null;
        setFilterHospitalName(null);
        lazyState.current.Email = null;
        setFilterEmail(null);
        lazyState.current.mobileNo = null;
        setFilterMobileNo(null);
        lazyState.current.Company = null;
        setFilterCompany(null);
        lazyState.current.BoardingCity = null;
        setFilterBoardingCity(null);
        lazyState.current.City = null;
        setFilterCity(null);
        lazyState.current.State = null;
        setFilterState(null);
        lazyState.current.Country = null;
        setFilterCountry(null);
        lazyState.current.filterDesignation = null;
        lazyState.current.filterGender = null;
        lazyState.current.filtertoeventcity = null;
        lazyState.current.filterfromeventcity = null;

        lazyState.current.ArrivalTimefilter = null;
        lazyState.current.DepartureTimefilter = null;
        lazyState.current.Designationfilter = null;
        lazyState.current.Qualificationfilter = null;

        setInBatchfilter(null);
        lazyState.current.inbatchfilter = null;
        setOutBatchfilter(null);
        lazyState.current.outbatchfilter = null;
        lazyState.current.TricoConfirmedfilter = null;
        lazyState.current.serialnumber = null;
        setSerialNumber(null);

        getparticipantlist(lazyState.current, false);
    };

    // Right Side Toolbar
    const rightToolbarTemplate = () => {
        return (
            <div className="my-2">
                <Button
                    disabled={
                        lazyState.current.FullName == null &&
                        lazyState.current.hospitalName == null &&
                        lazyState.current.Email == null &&
                        lazyState.current.mobileNo == null &&
                        lazyState.current.Company == null &&
                        lazyState.current.BoardingCity == null &&
                        lazyState.current.City == null &&
                        lazyState.current.State == null &&
                        lazyState.current.Country == null &&
                        lazyState.current.filterDesignation == null &&
                        lazyState.current.filterGender == null &&
                        lazyState.current.filterfromeventcity == null &&
                        lazyState.current.filtertoeventcity == null &&
                        lazyState.current.inbatchfilter == null &&
                        lazyState.current.outbatchfilter == null &&
                        lazyState.current.ArrivalTimefilter == null &&
                        lazyState.current.serialnumber == null &&
                        lazyState.current.DepartureTimefilter == null &&
                        lazyState.current.Designationfilter == null &&
                        lazyState.current.Qualificationfilter == null &&
                        lazyState.current.TricoConfirmedfilter == null
                            ? true
                            : false
                    }
                    icon="pi pi-filter-slash"
                    className="mr-2"
                    onClick={() => removeFilter()}
                    tooltip="Reset Filter"
                    tooltipOptions={{ position: "bottom", className: "showtooltip" }}
                    outlined
                ></Button>
                {/* {enableFilter ? <Button type="button" icon="pi pi-filter-slash" tooltip="Hide Filter" tooltipOptions={{ position: "bottom", className: "showtooltip" }} className=" mr-2 p-button-primary" onClick={(e) => setEnableFilter(false)} /> : <Button type="button" icon="pi pi-filter" tooltip="Show Filter" tooltipOptions={{ position: "bottom", className: "showtooltip" }} className="p-button-outlined mr-2" onClick={(e) => setEnableFilter(true)} />} */}
                <div className="my-2">{header}</div>
                <AccessControl allowedPermissions={["export:participant"]}>
                    <Button
                        disabled={!(participantlist != null && participantlist.length > 0)}
                        tooltip="Export Excel"
                        tooltipOptions={{ position: "bottom", className: "showtooltip" }}
                        onClick={() => {
                            getparticipantlist(null,true);
                        }}
                        icon="pi pi-file-excel"
                        className="mr-2"
                        outlined
                    />
                </AccessControl>
            </div>
        );
    };

    // On Dropdown Change
    const onDropdownChange = (e, name) => {
        let _participant = { ...participanthotel };
        if (name === "occupancy") {
            if (e.target.value.code == true && id.length <= HotelroomDetails.sglroomremaining) {
                _participant["occupancy"] = e.target && e.target.value.name;
                _participant["occupancycode"] = e.target && e.target.value.code;
                setParticipanthotel(_participant);
            } else if (e.target.value.code == false && id.length / 2 <= HotelroomDetails.dlbroomremaining) {
                _participant["occupancy"] = e.target && e.target.value.name;
                _participant["occupancycode"] = e.target && e.target.value.code;
                setParticipanthotel(_participant);
            } else {
                toast.current.show({ severity: "warn", summary: "Warning", detail: "You can not select this occupancy as rooms are not available", life: 3000 });
            }
        }
        if (name === "hotelname") {
            // setHotelId(e.target.value.code);
            _participant["hotelid"] = e.target && e.target.value.code;
            _participant["hotelname"] = e.target && e.target.value.name;
            setParticipanthotel(_participant);
            _participant["occupancy"] = "";
            _participant["occupancycode"] = "";
            GetSelectedHotelAvailableRooms(e.target.value.code);
        }
    };

    //#region Method for Column toggle
    const hidecolumns = [
        { field: "hospitalName", header: "Hospital" },
        // { field: "designation", header: "Designation" },        
        { field: "statename", header: "State" },
        { field: "countryname", header: "Country" },        
        { field: "travelmode_Outbound", header: "From Event City" },
    ];

    const onColumnToggle = (event) => {
        let selectedColumns = event.value;
        orderedSelectedColumns = hidecolumns.filter((col) => selectedColumns.some((sCol) => sCol.field === col.field));
        unSelectedColumns = hidecolumns.filter((o) => !selectedColumns.some((i) => i.field === o.field && i.header === o.header));
        unselectedColumnData();
        setSelectedColumns(orderedSelectedColumns);
    };

    const getData = () => {
        fetch("config.json", {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        })
        .then(function (response) {
            return response.json();
        })
        .then(function (data) {
            const travelDateDays = parseInt(data[13].TravelDateDays); // Convert to integer
            const eventDayOne = new Date(data[9].EventDayOneDt);
            const eventDayTwo = new Date(data[10].EventDayTwoDt);
            
            // Function to format the date as dd-mm-yyyy
            const formatDate = (date) => {
                const day = String(date.getDate()).padStart(2, '0');
                const month = String(date.getMonth() + 1).padStart(2, '0');
                const year = date.getFullYear();
                return `${day}-${month}-${year}`;
            };

             // Function to format the date as dd-mm-yyyy
             const formatDateCode = (date) => {
                const day = String(date.getDate()).padStart(2, '0');
                const month = String(date.getMonth() + 1).padStart(2, '0');
                const year = date.getFullYear();
                return `${year}-${month}-${day}`;
            };
    
            let dates = [];
            for (let i = travelDateDays; i >= 1; i--) {
                dates.push(new Date(eventDayOne.getTime() - i * 24 * 60 * 60 * 1000)); // Subtract i days from eventDayOne
            }
            dates.push(eventDayOne); 
            dates.push(eventDayTwo);
            for (let i = 1; i <= travelDateDays; i++) {
                dates.push(new Date(eventDayTwo.getTime() + i * 24 * 60 * 60 * 1000)); // Add i days to eventDayTwo
            }
    
            const formattedDates = dates.map((date) => {
                const formattedDate = formatDate(date);
                const formattedDateCode = formatDateCode(date);
                return { name: formattedDate, code: formattedDateCode };
            });    
            setDepartureDates(formattedDates); // Assuming you have a setter function for the dates
        });
    };
    
    
    
    const unselectedColumnData = () => {
        let isFiltered = false;
        if (unSelectedColumns) {
            unSelectedColumns.map((col) => {
                switch (col.header) {
                    case "Hospital":
                        if (lazyState.current.hospitalName != null) {
                            lazyState.current.hospitalName = null;
                            isFiltered = true;
                            return lazyState.current.hospitalName;
                        }
                        break;
                    case "Gender":
                        if (lazyState.current.filterGender != null) {
                            lazyState.current.filterGender = null;
                            isFiltered = true;
                            return lazyState.current.filterGender;
                        }
                        break;
                    case "Boarding City":
                        if (lazyState.current.BoardingCity != null) {
                            lazyState.current.BoardingCity = null;
                            isFiltered = true;
                            return setFilterBoardingCity(null);
                        }
                        break;
                    case "City":
                        if (lazyState.current.City != null) {
                            lazyState.current.City = null;
                            isFiltered = true;
                            return setFilterCity(null);
                        }
                        break;
                    case "State":
                        if (lazyState.current.State != null) {
                            lazyState.current.State = null;
                            isFiltered = true;
                            return setFilterState(null);
                        }
                        break;
                    case "Country":
                        if (lazyState.current.Country != null) {
                            lazyState.current.Country = null;
                            isFiltered = true;
                            return setFilterCountry(null);
                        }
                        break;
                    case "To Event City":
                        if (lazyState.current.filtertoeventcity != null) {
                            lazyState.current.filtertoeventcity = null;
                            isFiltered = true;
                            return lazyState.current.filtertoeventcity;
                        }
                        break;
                    case "From Event City":
                        if (lazyState.current.filterfromeventcity != null) {
                            lazyState.current.filterfromeventcity = null;
                            isFiltered = true;
                            return lazyState.current.filterfromeventcity;
                        }
                        break;
                }
            });
            if (isFiltered) {
                getparticipantlist(lazyState.current, false);
            }
        }
    };

    const header = (
        <div style={{ textAlign: "left", marginRight: "10px" }}>
            <MultiSelect value={selectedColumns} options={hidecolumns} optionLabel="header" placeholder="More Columns" onChange={onColumnToggle} style={{ width: "20em" }} display="chip" />
        </div>
    );

    const hideColumnsFilterTemplate = (column) => {
        switch (column) {
            case "Gender":
                return GenderFilterTemplate;
            case "State":
                return StateTemplate;
            case "Country":
                return CountryTemplate;
            case "To Event City":
                return ToEventCityTemplate;
            case "From Event City":
                return FromEventCityTemplate;
            case "Boarding City":
                return BoardingCityFilterTemplate;
            case "Hospital":
                return HospitalFilterTemplate;
        }
    };

    const sortColumn = {
        Gender: "Gender",
        City: "CityId",
        "To Event City": "Travelmode_Inbound",
        "From Event City": "Travelmode_Outbound",
        "Boarding City": "Boardingcity",
        Hospital: "HospitalName",
    };

    const hidecolumnComponents = selectedColumns.map((col) => {
        return <Column key={col.field} field={col.field} header={col.header} sortable headerStyle={{ width: "15%", minWidth: "15rem" }} sortField={sortColumn[col.header]} filter showFilterMenu={false} filterElement={hideColumnsFilterTemplate(col.header)} />;
    });
    //#endregion

    // Row Selection / Unselect Functions
    const onAllRowSelect = (e) => {
        setActionDisable(false);
        setid(
            e.data.map((i, k) => {
                return i.id;
            })
        );
    };

    const onRowSelect = (event) => {
        setActionDisable(false);
        setid((ing) => [...ing, event.data.id]);
    };

    const onRowUnselect = (event) => {
        if (id != undefined) {
            var removeidfromdata = id.indexOf(event.data.id);
            id.splice(removeidfromdata, 1);
            if (id.length == 0) {
                setActionDisable(true);
            }
        } else {
            var removefromarray = id.indexOf(event.data.id);
            id.splice(removefromarray, 1);
        }
    };

    const onAllRowUnSelect = (e) => {
        setActionDisable(true);
        setid(
            e.data.map((i, k) => {
                return i.id;
            })
        );
        id.splice(0, id.length);
    };

    const ArrivalTimerFilterTemplate = () => {
        return (
              <MultiSelect
                            value={lazyState.current.ArrivalTimefilter}
                            options={DepartureDates}
                            itemTemplate={ArrivalTimeFilterItemTemplate}
                            optionLabel="name"
                            onChange={(e) => ArrivalTimeFilterClick(e)}
                            placeholder="Select Any"
                            className="p-column-filter"
                            maxSelectedLabels={1}
                            style={{minWidth: '14rem'}}
                            filter
                        />
        );
    };

    const ArrivalTimeFilterBodyTemplate = (rowData) => {
        const ArrivalTime = rowData?.arrivalTime ? moment(rowData.arrivalTime).format("DD/MM/YYYY HH:mm") : "";
        return (
            <div className="flex align-items-center gap-2">
                <span>{ArrivalTime}</span>
            </div>
        );
    };
    const ArrivalTimeFilterItemTemplate = (option) => {
        return (
            <div className="flex align-items-center gap-2">
                <span>{option.name}</span>
            </div>
        );
    };

    const ArrivalTimeFilterClick = (e) => {
        lazyState.current.ArrivalTimefilter = e.target.value ? e.target.value : null;
        getparticipantlist({ ArrivalFilter: e.value },false);
        e.preventDefault();
    };



    const DepartureTimerFilterTemplate = () => {
        return (
              <MultiSelect
                            value={lazyState.current.DepartureTimefilter}
                            options={DepartureDates}
                            itemTemplate={DepartureTimeFilterItemTemplate}
                            optionLabel="name"
                            onChange={(e) => DepartureTimeFilterClick(e)}
                            placeholder="Select Any"
                            className="p-column-filter"
                            maxSelectedLabels={1}
                            style={{minWidth: '14rem'}}
                            filter
                        />
        );
    };

    const DepartureTimeFilterBodyTemplate = (rowData) => {
        const DepartureTime = rowData?.departureTime ? moment(rowData.departureTime).format("DD/MM/YYYY HH:mm") : "";
        return (
            <div className="flex align-items-center gap-2">
                <span>{DepartureTime}</span>
            </div>
        );
    };
    const DepartureTimeFilterItemTemplate = (option) => {
        return (
            <div className="flex align-items-center gap-2">
                <span>{option.name}</span>
            </div>
        );
    };

    const DepartureTimeFilterClick = (e) => {
        lazyState.current.DepartureTimefilter = e.target.value ? e.target.value : null;
        getparticipantlist({ DepartureTimefilter: e.value },false);
        e.preventDefault();
    };
    //Html Part
    return (
        <AccessControl allowedPermissions={["list:participant"]} renderNoAccess={() => <NoAccess />}>
            <div className="trico-Grid">
                <Toast ref={toast} />
                {isLoading ? (
                    <LoadingSpinner />
                ) : (
                    <>
                        <div className="p-col">
                            <div className="card">
                                <h5 className="headerfont">
                                    <span>
                                        <i className="fa fa-solid fa-bed mr-2"></i>Hotel Allocation
                                    </span>
                                </h5>
                                <Toolbar left={leftToolbarTemplate} right={rightToolbarTemplate} className="mb-3"></Toolbar>
                                <Tooltip target="#viewattendee" content="View Attendee" />
                                <Tooltip target="#mailto" content="Mail To" />
                                <Tooltip target="#callto" content="Call To" />
                                <DataTable
                                    size="small"
                                    ref={dt}
                                    stripedRows
                                    value={participantlist}
                                    onAllRowsSelect={onAllRowSelect}
                                    lazy
                                    rows={lazyState.current.rows}
                                    onSort={onSort}
                                    sortField={lazyState.current.sortField}
                                    sortOrder={lazyState.current.sortOrder}
                                    removableSort
                                    selectionMode={"checkbox"}
                                    selection={selectedParticipant}
                                    onSelectionChange={(e) => setSelectedParticipant(e.value)}
                                    onRowSelect={onRowSelect}
                                    onRowUnselect={onRowUnselect}
                                    onAllRowsUnselect={onAllRowUnSelect}
                                    filterDisplay={"row"}
                                    dataKey="id"
                                    scrollable
                                    scrollHeight="calc(100vh - 250px)"
                                >
                                    <Column frozen selectionMode="multiple" headerStyle={{ width: "0%", minWidth: "1rem" }}></Column>
                                    <Column alignFrozen="left" field="serialNumber" header="Id" sortField="SerialNumber" sortable frozen headerStyle={{ width: "3%", minWidth: "5rem" }} filter showFilterMenu={false} filterElement={SerialNumberFilterTemplate} showClearButton></Column>
                                    <Column frozen body={ViewAttendees} field="fullName" sortField="FullName" header="Name" sortable headerStyle={{ width: "20%", minWidth: "15rem" }} filter showFilterMenu={false} filterElement={UserFilterTemplate} style={{ overflowWrap: "anywhere" }}></Column>
                                    <Column
                                        field="arrivalTime"
                                        sortField="ArrivalTime"
                                        sortable                                        
                                        headerStyle={{ width: "10%", minWidth: "15rem" }}
                                        style={{ overflowWrap: "anywhere" }}
                                        header="Arrival" 
                                        filter showFilterMenu={false} filterElement={ArrivalTimerFilterTemplate} body={ArrivalTimeFilterBodyTemplate} filterField="arrivalTime" filterMenuStyle={{ width: '14rem' }}></Column>
                                    <Column
                                        field="departureTime"
                                        sortField="DepartureTime"
                                        sortable
                                        headerStyle={{ width: "10%", minWidth: "15rem" }}
                                        style={{ overflowWrap: "anywhere" }}
                                        header="Departure" 
                                        filter showFilterMenu={false} filterElement={DepartureTimerFilterTemplate} body={DepartureTimeFilterBodyTemplate} filterField="departureTime" filterMenuStyle={{ width: '14rem' }} ></Column>
                                    <Column field="tricoConfirmed" sortField="TricoConfirmed" sortable header="Trico Confirmed" headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} filter showFilterMenu={false} filterElement={TricoConfirmedFilterTemplate} body={TricoConfirmedFilterBodyTemplate} filterField="tricoConfirmed" filterMenuStyle={{ width: '14rem' }} ></Column>    
                                    <Column field="inBatchBooking" header="In Batch" sortField="inBatchBooking" sortable filter headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere", minWidth: '14rem' }} showFilterMenu={false} filterElement={InBatchFilterTemplate} body={InBatchFilterBodyTemplate} filterField="InBatchBooking" filterMenuStyle={{ width: '14rem' }}></Column>
                                    <Column field="gender" header="Gender" sortField="Gender" sortable headerStyle={{ width: "5%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} filter showFilterMenu={false} filterElement={GenderFilterTemplate}></Column>
                                    <Column field="designation" header="Designation" sortField="Designation" sortable filter headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ minWidth: '14rem', overflowWrap: "anywhere" }} showFilterMenu={false} filterElement={DesignationFilterTemplate} body={DesignationFilterBodyTemplate} filterField="designation" filterMenuStyle={{ width: '14rem' }}></Column>
                                    <Column field="qualification" header="Qualification" sortField="Qualification" sortable filter headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ minWidth: '14rem', overflowWrap: "anywhere" }} showFilterMenu={false} filterElement={QualificationFilterTemplate} body={QualificationFilterBodyTemplate} filterField="qualification" filterMenuStyle={{ width: '14rem' }}></Column>
                                    <Column field="cityname" header="City" sortField="cityname" sortable filter headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere", minWidth: '14rem' }} showFilterMenu={false} filterElement={CityFilterTemplate} body={CityFilterBodyTemplate} filterField="City" filterMenuStyle={{ width: '14rem' }}></Column>
                                    <Column field="boardingcityname" header="Boarding City" sortField="boardingcityname" sortable filter headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere", minWidth: '14rem' }} showFilterMenu={false} filterElement={BoardingCityFilterTemplate} body={BoardingCityFilterBodyTemplate} filterField="BoardingCity" filterMenuStyle={{ width: '14rem' }}></Column>
                                    <Column field="company" header="Company" sortField="company" sortable headerStyle={{ width: "25%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} filter showFilterMenu={false} filterElement={CompanyFilterTemplate} body={CompanyFilterBodyTemplate} filterField="Company" filterMenuStyle={{ width: '14rem' }} ></Column>
                                    <Column field="travelmode_Inbound" sortField="ToEventCity" header="To Event City" sortable headerStyle={{ width: "10%", minWidth: "15rem" }} filter showFilterMenu={false} filterElement={ToEventCityTemplate} style={{ overflowWrap: "anywhere" }}></Column>
                                    <Column body={ButtonPhoneto} sortField="MobileNo" field="mobileNo" header="Mobile No" sortable headerStyle={{ width: "10%", minWidth: "15rem" }} filter showFilterMenu={false} filterElement={MobileFilterTemplate} style={{ overflowWrap: "anywhere" }}></Column>
                                    <Column body={ButtonMailto} sortField="Email" field="email" header="Email" sortable headerStyle={{ width: "10%", minWidth: "15rem" }} filter showFilterMenu={false} filterElement={EmailFilterTemplate} style={{ overflowWrap: "anywhere" }}></Column>
                                    <Column field="outBatchBooking" header="Out Batch" sortField="outBatchBooking" sortable filter headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere", minWidth: '14rem' }} showFilterMenu={false} filterElement={OutBatchFilterTemplate} body={OutBatchFilterBodyTemplate} filterField="OutBatchBooking" filterMenuStyle={{ width: '14rem' }}></Column>
                                    {/* <Column field="designation" sortField="Designation" header="Designation" sortable headerStyle={{ width: '15%', minWidth: '15rem' }} filter showFilterMenu={false} filterElement={DesignationFilterTemplate}></Column> */}
                                    {/* <Column field="hospitalName" sortField="HospitalName" header="Hospital" sortable headerStyle={{ width: '15%', minWidth: '15rem' }} filter showFilterMenu={false} filterElement={HospitalFilterTemplate}></Column> */}
                                    {hidecolumnComponents}
                                </DataTable>
                                <Paginator
                                    className="justify-content-end"
                                    style={{ marginRight: "15px" }}
                                    first={lazyState.current.first}
                                    rows={lazyState.current.rows}
                                    totalRecords={totalRecords}
                                    template={template2}
                                    rowsPerPageOptions={RowsPerPageDropdown}
                                    onPageChange={onBasicPageChange}
                                ></Paginator>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </AccessControl>
    );
};

export default HotelAllocation;