import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import axios from 'axios';
import { InputText } from 'primereact/inputtext';
import LoadingSpinner from "../Spinner/LoadingSpinner";
import { Paginator } from 'primereact/paginator';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import { Button } from "primereact/button";
import { Dialog } from 'primereact/dialog';
import { Toolbar } from 'primereact/toolbar';
import classNames from 'classnames';
import '../../assets/layout/styling.css';
import exportData from "../../services/exportToExcelService";
import AccessControl from "../AccessControl";
import NoAccess from "../NoAccess";
import { RowsPerPageDropdown } from "../../enums/RowsPerPageOptions";
import { MultiSelect } from 'primereact/multiselect';

const CompanyWiseAttendees = () => {
    const [CompanyWiseAttendeesData, setCompanyWiseAttendeesData] = React.useState([]);
    const [companyName, setCompanyName] = React.useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [isExportExcel, setIsExportExcel] = useState(false);
    const [companyList, setCompanyList] = useState([]);

    const toast = useRef(null);
    const dt = useRef(null);
    const lazyState = useRef({
        first: 0,
        rows: RowsPerPageDropdown[0],
        page: 1,
        sortField: null,
        sortOrder: 1,
        CompanyFilter: null
    }); // For Sorting And Pagination Purpose

    useEffect(() => {
        getCompanyWiseAttendees();
        document.documentElement.style.setProperty('overflow', 'hidden');
        getFilterListsData();
    }, []);

    const getCompanyWiseAttendees = (isExportExcel = false, event) => {
        //Company
        var listCompanyFilterString = null;
        if (lazyState.current.CompanyFilter != null && lazyState.current.CompanyFilter != undefined && lazyState.current.CompanyFilter != '') {
            if (lazyState.current.CompanyFilter.length > 0) {
                if (lazyState.current.CompanyFilter.length > 1) {
                    listCompanyFilterString = lazyState.current.CompanyFilter.map(item => item.name).join(',');
                } else {
                    listCompanyFilterString = lazyState.current.CompanyFilter.map(item => item.name)[0];
                }
            }
        }
        setIsLoading(true);
        setCompanyWiseAttendeesData([]);
        if (!isExportExcel) setCompanyWiseAttendeesData([]);
        axios.post('/api/Report/GetAllCompanyAttendees', {
            paginationRequired: !isExportExcel,
            SortDirection: lazyState.current.sortOrder,
            PageSize: lazyState.current.rows,
            CurrentPageNumber: event && event.rows ? ((event.first / event.rows) + 1) : lazyState.current.page,
            SortField: event ? event.sortField : "Name",
            CompanyName: listCompanyFilterString ? listCompanyFilterString : null
        }).then(Response => {
            setTotalRecords(Response.data.totalRecords);
            setCompanyWiseAttendeesData(Response.data.data);
            let tempData = [];
            for (let dataObj of Response.data.data) {
                let excelcolumnData = {
                    Company: dataObj.companyName,
                    Approved: dataObj.approvedstatus,
                    Rejected: dataObj.rejectedstatus,
                    Cancelled: dataObj.cancelledstatus,
                    Present: dataObj.presetattendees,
                };
                tempData.push(excelcolumnData);
            };
            if (isExportExcel) {
                exportData(tempData, 'CompanyWiseAttendee');
                setIsLoading(false);
                return false;
            };
            setIsLoading(false);
        }).catch(error => {
            setIsLoading(false);
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Oops! Something Went Wrong!', life: 3000 });
        })
    }


    const getFilterListsData = () => {
        setIsLoading(true);
        axios
            .get("/api/Participant/GetAllFilterListData")
            .then((Response) => {
                const separatedLists = Response.data.reduce((acc, item) => {
                    // Create a new array for each type if it doesn't exist
                    if (!acc[item.type]) {
                        acc[item.type] = [];
                    }
                    // Push the formatted item to the respective array
                    acc[item.type].push({ name: item.name, code: item.name });
                    return acc;
                }, {});

                // Sort each list by 'name'
                Object.keys(separatedLists).forEach(type => {
                    separatedLists[type].sort((a, b) => a.name.localeCompare(b.name));
                });
                setCompanyList(separatedLists.Company || []);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                toast.current.show({ severity: "error", summary: "Error", detail: "Oops! Something Went Wrong!", life: 3000 });
            });
    };

    // For Sorting 
    const onSort = (event) => {
        lazyState.current.sortField = event.sortField;
        lazyState.current.sortOrder = event.sortOrder;
        lazyState.current.first = event.first;
        lazyState.current.rows = event.rows;
        getCompanyWiseAttendees(isExportExcel, lazyState.current);
    };

    //pagination
    const onBasicPageChange = (event) => {
        // const newPage = event.first / event.rows + 1;
        // if (newPage !== lazyState.current.first / lazyState.current.rows + 1) {
        setIsLoading(true);
        lazyState.current.sortField = lazyState.current.sortField;
        lazyState.current.sortOrder = lazyState.current.sortOrder;
        lazyState.current.first = event.first;
        lazyState.current.rows = event.rows;
        getCompanyWiseAttendees(isExportExcel, lazyState.current);
        setIsLoading(false);
        // };
    };

    const CompanyFilterTemplate = () => {
        return (
            <MultiSelect
                value={lazyState.current.CompanyFilter}
                options={companyList}
                itemTemplate={CompanyFilterItemTemplate}
                optionLabel="name"
                onChange={(e) => CompanyFilterClick(e)}
                placeholder="Select Any"
                className="p-column-filter"
                maxSelectedLabels={1}
                style={{ minWidth: '14rem' }}
                filter
            />
        );
    };

    const CompanyFilterItemTemplate = (option) => {
        return (
            <div className="flex align-items-center gap-2">
                <span>{option.name}</span>
            </div>
        );
    };

    const CompanyFilterBodyTemplate = (rowData) => {
        const company = rowData.companyName;
        return (
            <div className="flex align-items-center gap-2">
                <span>{company}</span>
            </div>
        );
    };

    const CompanyFilterClick = (e) => {
        lazyState.current.CompanyFilter = e.target.value ? e.target.value : null;
        getCompanyWiseAttendees(false, { CompanyFilter: e.value });
        e.preventDefault();
    }

    // Right Side Toolbar
    const rightToolbarTemplate = () => {
        return (
            <div className="my-2">
                <Button disabled={lazyState.current.Company == null ? true : false} type="button"
                    icon="pi pi-filter-slash" className=" mr-2 p-button-primary"
                    onClick={(e) => { lazyState.current.Company = null; setCompanyName(null); getCompanyWiseAttendees(isExportExcel, lazyState.current) }} tooltip="Reset Filter" tooltipOptions={{ position: "bottom", className: "showtooltip" }} outlined></Button>
                {/* <AccessControl
                    allowedPermissions={["export:batch"]}> */}
                <Button disabled={!(CompanyWiseAttendeesData != null && CompanyWiseAttendeesData.length > 0)} onClick={() => { getCompanyWiseAttendees(true, lazyState.current); }} icon="pi pi-file-excel" tooltip="Export Excel" tooltipOptions={{ position: "bottom", className: "showtooltip" }} outlined />
                {/* </AccessControl> */}
            </div>
        )
    };

    //Template for pagination
    const template2 = {
        layout: ' CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown',
        'CurrentPageReport': (options) => {
            return (
                <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '19%', textAlign: 'center' }}>
                    Showing {options.first} to {options.last} of {options.totalRecords} entries
                </span>
            )
        },
        RowsPerPageDropdown: (options) => {
            return <span>Items Per Page :<Dropdown value={options.value} options={RowsPerPageDropdown} onChange={options.onChange} /></span>;
        },
    };

    return (
        <div className="trico-Grid">
            {isLoading ? <LoadingSpinner /> :
                <>
                    <div className="p-col">
                        <div className="card">
                            <h5 className="headerfont"><span>Company Wise Attendees</span></h5>
                            <Toolbar right={rightToolbarTemplate} className="mb-3"></Toolbar>
                            <DataTable size="small" stripedRows ref={dt} value={CompanyWiseAttendeesData} lazy rows={lazyState.current.rows}
                                onSort={onSort} sortField={lazyState.current.sortField} sortOrder={lazyState.current.sortOrder}
                                removableSort dataKey="id" className="datatable-responsive" responsiveLayout="scroll"
                                filterDisplay={"row"} scrollable scrollHeight="calc(100vh - 250px)">
                                <Column field="companyName" header="Company" sortField="companyName" sortable headerStyle={{ width: "25%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} filter showFilterMenu={false} filterElement={CompanyFilterTemplate} body={CompanyFilterBodyTemplate} filterField="Company" filterMenuStyle={{ width: '14rem' }} ></Column>
                                <Column field="approvedstatus" sortable sortField='approvedstatus' header="Approved"></Column>
                                <Column field="rejectedstatus" sortable sortField='rejectedstatus' header="Reject"></Column>
                                <Column field="cancelledstatus" sortable sortField='cancelledstatus' header="Cancelled"></Column>
                                <Column field="presetattendees" sortable sortField='presetattendees' header="Present"></Column>
                            </DataTable>
                            <Paginator className='justify-content-end' style={{ marginRight: "15px" }}
                                first={lazyState.current.first} rows={lazyState.current.rows} totalRecords={totalRecords} template={template2} rowsPerPageOptions={RowsPerPageDropdown} onPageChange={onBasicPageChange} ></Paginator>
                        </div>
                    </div>

                </>
            }
        </div>


    )
}

export default CompanyWiseAttendees;