import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import axios from "axios";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Paginator } from "primereact/paginator";
import { Link } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import LoadingSpinner from "../Spinner/LoadingSpinner";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { RowsPerPageDropdown } from "../../enums/RowsPerPageOptions";
import { Tooltip } from "primereact/tooltip";
import * as GlobalEnum from "../../enums/GlobalEnum";
import * as Designations from "../../enums/Designations";
import * as ArrivalDate from "./../../enums/ArrivalDate";
import moment from "moment";
import * as Travelmodes from "../../enums/Travelmodes";
import { MultiSelect } from "primereact/multiselect";


const ViewAttendeeStatus = () => {
    const [participantlist, setParticipantlist] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [fullName, setfullName] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [companyNameFilter, setcompanyNameFilter] = useState(null);
    const [filterEmail, setFilterEmail] = useState(null);
    const [filterMobileNo, setFilterMobileNo] = useState(null);
    const [SerialNumber, setSerialNumber] = useState(null);
    const [HotelFilter, setHotelFilter] = useState(null);
    const [InBatchfilter, setInBatchfilter] = useState(null);
    const [OutBatchfilter, setOutBatchfilter] = useState(null);
    const [boardingcityfilter, setboardingcityfilter] = useState(null);
    const [Cityfilter, setCityfilter] = useState(null);
    const [filterFlightNo, setFilterFlightNo] = useState(null);
    const [filterReturnFlightNo, setFilterReturnFlightNo] = useState(null);
    const [companyList, setCompanyList] = useState([]);
    const [inBatchList, setInBatchList] = useState([]);
    const [outBatchList, setOutBatchList] = useState([]);
    const [tricoConfirmedList, setTricoConfirmedList] = useState([]);

    const lazyState = useRef({
        first: 0,
        rows: RowsPerPageDropdown[0],
        page: 1,
        sortField: null,
        sortOrder: 1,
        CompanyFilter: null,
        ParticipantNameFilter: null,
        EmailFilter: null,
        MobileNoFilter: null,
        BoardingCityFilter: null,
        serialnumber: null,
        IsArrivedfilter: null,
        IsCheckedInfilter: null,
        HotelFilter: null,
        inbatchfilter: null,
        outbatchfilter: null,
        TricoConfirmedfilter: null,
        Designationfilter: null,
        CityFilter: null,
        ArrivalTimefilter: null,
        DepartureTimefilter: null,
        flightNo: null,
        returnflightno: null,
        Toeventcityfilter: null,
        Fromeventcityfilter: null,
        day1presentfilter: null,
        day2presentfilter: null
    }); // For Sorting And Pagination Purpose

    // Const
    const toast = useRef(null);
    const dt = useRef(null);

    // On Screen Load
    useEffect(() => {
        document.documentElement.style.setProperty("overflow", "auto");
        getparticipantlist();
        getFilterListsData();
    }, []);

    // Get All Participants
    const getparticipantlist = (event) => {
        //In Booking Batch
        var listInBatchFilterString = null;
        if (lazyState.current.inbatchfilter != null && lazyState.current.inbatchfilter != undefined && lazyState.current.inbatchfilter != '') {
            if (lazyState.current.inbatchfilter.length > 0) {
                if (lazyState.current.inbatchfilter.length > 1) {
                    listInBatchFilterString = lazyState.current.inbatchfilter.map(item => item.name).join(',');
                } else {
                    listInBatchFilterString = lazyState.current.inbatchfilter.map(item => item.name)[0];
                }
            }
        }

        //Out Booking Batch
        var listOutBatchFilterString = null;
        if (lazyState.current.outbatchfilter != null && lazyState.current.outbatchfilter != undefined && lazyState.current.outbatchfilter != '') {
            if (lazyState.current.outbatchfilter.length > 0) {
                if (lazyState.current.outbatchfilter.length > 1) {
                    listOutBatchFilterString = lazyState.current.outbatchfilter.map(item => item.name).join(',');
                } else {
                    listOutBatchFilterString = lazyState.current.outbatchfilter.map(item => item.name)[0];
                }
            }
        }

        //Trico Confirmed Batch
        var listTricoConfirmedFilterString = null;
        if (lazyState.current.TricoConfirmedfilter != null && lazyState.current.TricoConfirmedfilter != undefined && lazyState.current.TricoConfirmedfilter != '') {
            if (lazyState.current.TricoConfirmedfilter.length > 0) {
                if (lazyState.current.TricoConfirmedfilter.length > 1) {
                    listTricoConfirmedFilterString = lazyState.current.TricoConfirmedfilter.map(item => item.name).join(',');
                } else {
                    listTricoConfirmedFilterString = lazyState.current.TricoConfirmedfilter.map(item => item.name)[0];
                }
            }
        }

        //Company
        var listCompanyFilterString = null;
        if (lazyState.current.CompanyFilter != null && lazyState.current.CompanyFilter != undefined && lazyState.current.CompanyFilter != '') {
            if (lazyState.current.CompanyFilter.length > 0) {
                if (lazyState.current.CompanyFilter.length > 1) {
                    listCompanyFilterString = lazyState.current.CompanyFilter.map(item => item.name).join(',');
                } else {
                    listCompanyFilterString = lazyState.current.CompanyFilter.map(item => item.name)[0];
                }
            }
        }
        setIsLoading(true);
        axios
            .post("/api/Participant/GetAll", {
                SortDirection: lazyState.current.sortOrder,
                PageSize: lazyState.current.rows,
                CurrentPageNumber: event && event.rows ? event.first / event.rows + 1 : lazyState.current.page,
                SortField: event && event.sortField ? event.sortField : "",
                registrationStatus: "APPROVED",
                CompanyFilter: listCompanyFilterString ? listCompanyFilterString : null,
                FullName: lazyState.current.ParticipantNameFilter ? lazyState.current.ParticipantNameFilter.trim() : null,
                Email: lazyState.current.EmailFilter ? lazyState.current.EmailFilter.trim() : null,
                MobileNo: lazyState.current.MobileNoFilter ? lazyState.current.MobileNoFilter.trim() : null,
                BoardingCity: lazyState.current.BoardingCityFilter ? lazyState.current.BoardingCityFilter.trim() : null,
                SerialNumber: lazyState.current.serialnumber ? lazyState.current.serialnumber.trim() : null,
                IsArrived: lazyState.current.IsArrivedfilter ? lazyState.current.IsArrivedfilter.code.trim() : null,
                IsCheckedIn: lazyState.current.IsCheckedInfilter ? lazyState.current.IsCheckedInfilter.code.trim() : null,
                HotelFilter: lazyState.current.HotelFilter ? lazyState.current.HotelFilter.trim() : null,
                InBatchBooking: listInBatchFilterString ? listInBatchFilterString : null,
                OutBatchBooking: listOutBatchFilterString ? listOutBatchFilterString : null,
                TricoConfirmed: listTricoConfirmedFilterString || (listTricoConfirmedFilterString === 'Not Assigned' ? 'Not Assigned' : null),
                City: lazyState.current.CityFilter ? lazyState.current.CityFilter.trim() : null,
                Designation: lazyState.current.Designationfilter ? lazyState.current.Designationfilter.name.trim() : null,
                ArrivalFilter: lazyState.current.ArrivalTimefilter ? lazyState.current.ArrivalTimefilter.code : null,
                Present: lazyState.current.day1presentfilter ? lazyState.current.day1presentfilter.code : null,
                DayTwoPresent:lazyState.current.day2presentfilter ? lazyState.current.day2presentfilter.code : null,
                DepartureFilter: lazyState.current.DepartureTimefilter ? lazyState.current.DepartureTimefilter.code : null,
                FlightNo: lazyState.current.flightNo ? lazyState.current.flightNo.trim() : null,
                ArrivalReturnFlightNo: lazyState.current.returnflightno ? lazyState.current.returnflightno.trim() : null,
                ToEventCity: lazyState.current.Toeventcityfilter ? lazyState.current.Toeventcityfilter.code.trim() : null,
                FromEventCity: lazyState.current.Fromeventcityfilter ? lazyState.current.Fromeventcityfilter.code.trim() : null,
                ParticipantHotel: true,
            })
            .then((Response) => {
                setTotalRecords(Response.data.totalRecords);
                setParticipantlist(Response.data.data);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                toast.current.show({ severity: "error", summary: "Error", detail: "Oops! Something Went Wrong!", life: 3000 });
            });
    };

    const getFilterListsData = () => {
        setIsLoading(true);
        axios
            .get("/api/Participant/GetAllFilterListData")
            .then((Response) => {
                const separatedLists = Response.data.reduce((acc, item) => {
                    // Create a new array for each type if it doesn't exist
                    if (!acc[item.type]) {
                        acc[item.type] = [];
                    }
                    // Push the formatted item to the respective array
                    acc[item.type].push({ name: item.name, code: item.name });
                    return acc;
                }, {});

                // Sort each list by 'name'
                Object.keys(separatedLists).forEach(type => {
                    separatedLists[type].sort((a, b) => a.name.localeCompare(b.name));
                });
                setCompanyList(separatedLists.Company || []);
                setInBatchList(separatedLists.InBatch || []);
                setOutBatchList(separatedLists.OutBatch || []);
                setTricoConfirmedList(separatedLists.TricoConfirmed || []);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                toast.current.show({ severity: "error", summary: "Error", detail: "Oops! Something Went Wrong!", life: 3000 });
            });
    };


    const UserFilterTemplate = () => {
        return <InputText id="fullName" value={fullName} placeholder="Enter First Name" onChange={(e) => setfullName(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} />;
    };

    const SerialNumberFilterTemplate = () => {
        return <InputText id="serialnumber" value={SerialNumber} placeholder="Sr No." onChange={(e) => setSerialNumber(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "5rem" }} />;
    };

    const BoardingCityFilterTemplate = () => {
        return <InputText id="boardingcityname" value={boardingcityfilter} placeholder="Enter Boarding City" onChange={(e) => setboardingcityfilter(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "12rem" }} />;
    };
    const CompanyFilterTemplate = () => {
        return (
            <MultiSelect
                value={lazyState.current.CompanyFilter}
                options={companyList}
                itemTemplate={CompanyFilterItemTemplate}
                optionLabel="name"
                onChange={(e) => CompanyFilterClick(e)}
                placeholder="Select Any"
                className="p-column-filter"
                maxSelectedLabels={1}
                style={{ minWidth: '14rem' }}
                filter
            />
        );
    };

    const CompanyFilterItemTemplate = (option) => {
        return (
            <div className="flex align-items-center gap-2">
                <span>{option.name}</span>
            </div>
        );
    };

    const CompanyFilterBodyTemplate = (rowData) => {
        const company = rowData.company;
        return (
            <div className="flex align-items-center gap-2">
                <span>{company}</span>
            </div>
        );
    };

    const CompanyFilterClick = (e) => {
        lazyState.current.CompanyFilter = e.target.value ? e.target.value : null;
        getparticipantlist({ CompanyFilter: e.value });
        e.preventDefault();
    }

    const EmailFilterTemplate = () => {
        return <InputText id="Email" value={filterEmail} placeholder="Enter Email" onChange={(e) => setFilterEmail(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "12rem" }} />;
    };

    const MobileFilterTemplate = () => {
        return <InputText id="Mobile" value={filterMobileNo} placeholder="Enter Mobile No." onChange={(e) => setFilterMobileNo(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "12rem" }} />;
    };

    const InBatchFilterTemplate = () => {
        return (
            <MultiSelect
                value={lazyState.current.inbatchfilter}
                options={inBatchList}
                itemTemplate={InBatchFilterItemTemplate}
                optionLabel="name"
                onChange={(e) => InBatchFilterClick(e)}
                placeholder="Select Any"
                className="p-column-filter"
                maxSelectedLabels={1}
                style={{ minWidth: '14rem' }}
                filter
            />
        );
    };

    const InBatchFilterItemTemplate = (option) => {
        return (
            <div className="flex align-items-center gap-2">
                <span>{option.name}</span>
            </div>
        );
    };

    const InBatchFilterBodyTemplate = (rowData) => {
        const inBatch = rowData.inBatchBooking;
        return (
            <div className="flex align-items-center gap-2">
                <span>{inBatch}</span>
            </div>
        );
    };

    const InBatchFilterClick = (e) => {
        lazyState.current.inbatchfilter = e.target.value ? e.target.value : null;
        getparticipantlist( { InBatch: e.value });
        e.preventDefault();
    }

    const OutBatchFilterTemplate = () => {
        return (
            <MultiSelect
                value={lazyState.current.outbatchfilter}
                options={outBatchList}
                itemTemplate={OutBatchFilterItemTemplate}
                optionLabel="name"
                onChange={(e) => OutBatchFilterClick(e)}
                placeholder="Select Any"
                className="p-column-filter"
                maxSelectedLabels={1}
                style={{ minWidth: '14rem' }}
                filter
            />
        );
    };

    const OutBatchFilterItemTemplate = (option) => {
        return (
            <div className="flex align-items-center gap-2">
                <span>{option.name}</span>
            </div>
        );
    };

    const OutBatchFilterBodyTemplate = (rowData) => {
        const outBatch = rowData.outBatchBooking;
        return (
            <div className="flex align-items-center gap-2">
                <span>{outBatch}</span>
            </div>
        );
    };

    const OutBatchFilterClick = (e) => {
        lazyState.current.outbatchfilter = e.target.value ? e.target.value : null;
        getparticipantlist( { OutBatch: e.value });
        e.preventDefault();
    }

    const TricoConfirmedFilterTemplate = () => {
        return (
            <MultiSelect
                value={lazyState.current.TricoConfirmedfilter}
                options={tricoConfirmedList}
                itemTemplate={TricoConfirmedFilterItemTemplate}
                optionLabel="name"
                onChange={(e) => TricoConfirmedFilterClick(e)}
                placeholder="Select Any"
                className="p-column-filter"
                maxSelectedLabels={1}
                style={{ minWidth: '14rem' }}
                filter
            />
        );
    };

    const TricoConfirmedFilterBodyTemplate = (rowData) => {
        const tricoConfirmed = rowData.tricoConfirmed;
        return (
            <div className="flex align-items-center gap-2">
                <span>{tricoConfirmed}</span>
            </div>
        );
    };
    const TricoConfirmedFilterItemTemplate = (option) => {
        return (
            <div className="flex align-items-center gap-2">
                <span>{option.name}</span>
            </div>
        );
    };

    const TricoConfirmedFilterClick = (e) => {
        lazyState.current.TricoConfirmedfilter = e.target.value ? e.target.value : null;
        getparticipantlist({ TricoConfirmed: e.value });
        e.preventDefault();
    };


    const IsArrivedFilterTemplate = () => {
        return (
            <Dropdown
                value={lazyState.current.IsArrivedfilter}
                options={GlobalEnum.GlobalEnum}
                optionLabel="name"
                showClear
                onChange={(e) => {
                    lazyState.current.IsArrivedfilter = e.target.value ? e.target.value : null;
                    getparticipantlist({ IsArrived: e.value });
                    e.preventDefault();
                }}
                placeholder="Select Arrived Status"
                className="p-column-filter"
            />
        );
    };

    const Day1PresentFilterTemplate = () => {
        return (
            <Dropdown
                value={lazyState.current.day1presentfilter}
                options={GlobalEnum.GlobalEnum}
                optionLabel="name"
                showClear
                onChange={(e) => {
                    lazyState.current.day1presentfilter = e.target.value ? e.target.value : null;
                    getparticipantlist({ Present: e.value });
                    e.preventDefault();
                }}
                placeholder="Select Arrived Status"
                className="p-column-filter"
            />
        );
    };

    const Day2PresentFilterTemplate = () => {
        return (
            <Dropdown
                value={lazyState.current.day2presentfilter}
                options={GlobalEnum.GlobalEnum}
                optionLabel="name"
                showClear
                onChange={(e) => {
                    lazyState.current.day2presentfilter = e.target.value ? e.target.value : null;
                    getparticipantlist({ DayTwoPresent: e.value });
                    e.preventDefault();
                }}
                placeholder="Select Arrived Status"
                className="p-column-filter"
            />
        );
    };


    const IsCheckedInFilterTemplate = () => {
        return (
            <Dropdown
                value={lazyState.current.IsCheckedInfilter}
                options={GlobalEnum.GlobalEnum}
                optionLabel="name"
                showClear
                onChange={(e) => {
                    lazyState.current.IsCheckedInfilter = e.target.value ? e.target.value : null;
                    getparticipantlist({ IsCheckedIn: e.value });
                    e.preventDefault();
                }}
                placeholder="Select CheckedIn"
                className="p-column-filter"
            />
        );
    };

    const ToEventCityTemplate = () => {
        return (
            <Dropdown
                value={lazyState.current.Toeventcityfilter}
                options={Travelmodes.Travelmode}
                optionLabel="name"
                showClear
                onChange={(e) => {
                    lazyState.current.Toeventcityfilter = e.target.value ? e.target.value : null;
                    getparticipantlist({ ToEventCity: e.value });
                    e.preventDefault();
                }}
                placeholder="Select Travel Mode "
                className="p-column-filter"
                style={{ minWidth: "12rem" }}
            />
        );
    };

    const FromEventCityTemplate = () => {
        return (
            <Dropdown
                value={lazyState.current.Fromeventcityfilter}
                options={Travelmodes.Travelmode}
                optionLabel="name"
                showClear
                onChange={(e) => {
                    lazyState.current.Fromeventcityfilter = e.target.value ? e.target.value : null;
                    getparticipantlist({ FromEventCity: e.value });
                    e.preventDefault();
                }}
                placeholder="Select Travel Mode"
                className="p-column-filter"
                style={{ minWidth: "12rem" }}
            />
        );
    };

    const HotelFilterTemplate = () => {
        return <InputText id="Hotel" value={HotelFilter} placeholder="Enter Hotel" onChange={(e) => setHotelFilter(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "12rem" }} />;
    };

    const DesignationFilterTemplate = () => {
        return (
            <Dropdown
                value={lazyState.current.Designationfilter}
                options={Designations.Designation}
                optionLabel="name"
                showClear
                onChange={(e) => {
                    lazyState.current.Designationfilter = e.target.value ? e.target.value : null;
                    getparticipantlist({ Designation: e.value });
                    e.preventDefault();
                }}
                placeholder="Select Designation"
                className="p-column-filter"
                style={{ minWidth: "12rem" }}
            />
        );
    };

    const CityFilterTemplate = (options) => {
        return <InputText id="city" value={Cityfilter} placeholder="Enter City" onChange={(e) => setCityfilter(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ minWidth: "12rem" }} />;
    };

    const ArrivalTimerFilterTemplate = () => {
        return (
            <Dropdown
                value={lazyState.current.ArrivalTimefilter}
                options={ArrivalDate.ArrivalDate}
                optionLabel="name"
                showClear
                onChange={(e) => {
                    lazyState.current.sortField = "ArrivalTime";
                    lazyState.current.ArrivalTimefilter = e.target.value ? e.target.value : null;
                    getparticipantlist({ ArrivalFilter: e.value }, lazyState.current);
                    e.preventDefault();
                }}
                placeholder="Select Arrival Time"
                className="p-column-filter"
                style={{ minWidth: "12rem" }}
            />
        );
    };
    const DepartureTimerFilterTemplate = () => {
        return (
            <Dropdown
                value={lazyState.current.DepartureTimefilter}
                options={ArrivalDate.ArrivalDate}
                optionLabel="name"
                showClear
                onChange={(e) => {
                    lazyState.current.sortField = "DepartureTime";
                    lazyState.current.DepartureTimefilter = e.target.value ? e.target.value : null;
                    getparticipantlist({ DepartureFilter: e.value }, lazyState.current);
                    e.preventDefault();
                }}
                placeholder="Select Departure Time"
                className="p-column-filter"
                style={{ minWidth: "12rem" }}
            />
        );
    };

    const FlightNoFilterTemplate = () => {
        return <InputText id="flightno" value={filterFlightNo} placeholder="Enter FlightNo" onChange={(e) => setFilterFlightNo(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ width: "150px" }} />;
    };
    const ReturnFlightNoFilterTemplate = () => {
        return <InputText id="returnflightno" value={filterReturnFlightNo} placeholder="Enter FlightNo" onChange={(e) => setFilterReturnFlightNo(e.target.value)} onKeyDown={(e) => UserFilterOnEnter(e)} style={{ width: "150px" }} />;
    };

    const UserFilterOnEnter = (e) => {
        if (e.key == "Enter") {
            if (e.target.value.trim() != null && e.target.value.trim() != "") {
                setIsLoading(true);
                let data = e.target.id;
                switch (data) {
                    case "fullName":
                        lazyState.current.ParticipantNameFilter = e.target.value ? e.target.value : null;
                        break;
                    case "boardingcityname":
                        lazyState.current.BoardingCityFilter = e.target.value ? e.target.value : null;
                        break;
                    case "city":
                        lazyState.current.CityFilter = e.target.value ? e.target.value : null;
                        break;
                    case "company":
                        lazyState.current.CompanyFilter = e.target.value ? e.target.value : null;
                        break;
                    case "Email":
                        lazyState.current.EmailFilter = e.target.value ? e.target.value : null;
                        break;
                    case "Mobile":
                        lazyState.current.MobileNoFilter = e.target.value ? e.target.value : null;
                        break;
                    case "serialnumber":
                        lazyState.current.serialnumber = e.target.value ? e.target.value : null;
                        break;
                    case "inbatch":
                        lazyState.current.inbatchfilter = e.target.value ? e.target.value : null;
                        break;
                    case "outbatch":
                        lazyState.current.outbatchfilter = e.target.value ? e.target.value : null;
                        break;
                    case "Hotel":
                        lazyState.current.HotelFilter = e.target.value ? e.target.value : null;
                        break;
                    case "flightno":
                        lazyState.current.flightNo = e.target.value ? e.target.value : null;
                        break;
                    case "returnflightno":
                        lazyState.current.returnflightno = e.target.value ? e.target.value : null;
                        break;
                }
                getparticipantlist(lazyState.current);
                setIsLoading(false);
            } else {
                setIsLoading(true);
                let data = e.target.id;
                switch (data) {
                    case "fullName":
                        lazyState.current.ParticipantNameFilter = null;
                        break;
                    case "boardingcityname":
                        lazyState.current.BoardingCityFilter = null;
                        break;
                    case "city":
                        lazyState.current.CityFilter = null;
                        break;
                    case "company":
                        lazyState.current.CompanyFilter = null;
                        break;
                    case "Email":
                        lazyState.current.EmailFilter = null;
                        break;
                    case "Mobile":
                        lazyState.current.MobileNoFilter = null;
                        break;
                    case "serialnumber":
                        lazyState.current.serialnumber = null;
                        break;
                    case "inbatch":
                        lazyState.current.inbatchfilter = null;
                        break;
                    case "outbatch":
                        lazyState.current.outbatchfilter = null;
                        break;
                    case "Hotel":
                        lazyState.current.HotelFilter = null;
                        break;
                    case "flightno":
                        lazyState.current.flightNo = null;
                        break;
                    case "returnflightno":
                        lazyState.current.returnflightno = null;
                        break;
                }
                getparticipantlist(lazyState.current);
                setIsLoading(false);
            }
        }
    };

    // For Sorting
    const onSort = (event) => {
        lazyState.current.sortField = event.sortField;
        lazyState.current.sortOrder = event.sortOrder;
        lazyState.current.first = event.first;
        lazyState.current.rows = event.rows;
        getparticipantlist(lazyState.current);
    };

    //pagination
    const onBasicPageChange = (event) => {
        setIsLoading(true);
        lazyState.current.sortField = lazyState.current.sortField;
        lazyState.current.sortOrder = lazyState.current.sortOrder;
        lazyState.current.first = event.first;
        lazyState.current.rows = event.rows;
        getparticipantlist(lazyState.current);
        setIsLoading(false);
    };

    //Template for pagination
    const template2 = {
        layout: " CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown",
        CurrentPageReport: (options) => {
            return (
                <span style={{ color: "var(--text-color)", userSelect: "none", width: "19%", textAlign: "center" }}>
                    Showing {options.first} to {options.last} of {options.totalRecords} entries
                </span>
            );
        },
        RowsPerPageDropdown: (options) => {
            return (
                <span>
                    Items Per Page :<Dropdown value={options.value} options={RowsPerPageDropdown} onChange={options.onChange} />
                </span>
            );
        },
    };

    const ButtonMailto = (rowData) => {
        return (
            <Link
                className="linkcolors"
                id="mailto"
                to="#"
                onClick={(e) => {
                    window.location.href = `mailto:${rowData.email}`;
                    e.preventDefault();
                }}
            >
                {rowData.email}
            </Link>
        );
    };

    const ButtonPhoneto = (rowData) => {
        return (
            <Link
                className="linkcolors"
                id="callto"
                to="#"
                onClick={(e) => {
                    window.location.href = `tel:${rowData.mobileNo}`;
                    e.preventDefault();
                }}
            >
                {rowData.mobileNo}
            </Link>
        );
    };

    const removeFilter = () => {
        lazyState.current.CompanyFilter = null;
        setcompanyNameFilter(null);
        lazyState.current.ParticipantNameFilter = null;
        setfullName(null);
        lazyState.current.BoardingCityFilter = null;
        setboardingcityfilter(null);
        lazyState.current.EmailFilter = null;
        setFilterEmail(null);
        lazyState.current.MobileNoFilter = null;
        setFilterMobileNo(null);
        lazyState.current.Genderfilter = null;
        lazyState.current.serialnumber = null;
        setSerialNumber(null);
        setInBatchfilter(null);
        lazyState.current.inbatchfilter = null;
        setOutBatchfilter(null);
        lazyState.current.outbatchfilter = null;
        lazyState.current.IsArrivedfilter = null;
        lazyState.current.IsCheckedInfilter = null;
        lazyState.current.HotelFilter = null;
        setHotelFilter(null);

        lazyState.current.CityFilter = null;
        setCityfilter(null);
        lazyState.current.Designationfilter = null;

        lazyState.current.ArrivalTimefilter = null;
        lazyState.current.DepartureTimefilter = null;

        lazyState.current.flightNo = null;
        setFilterFlightNo(null);

        lazyState.current.returnflightno = null;
        setFilterReturnFlightNo(null);

        lazyState.current.Toeventcityfilter = null;
        lazyState.current.Fromeventcityfilter = null;
        lazyState.current.day1presentfilter = null;
        lazyState.current.day2presentfilter = null;

        getparticipantlist();
    };

    // Right Side Toolbar
    const rightToolbarTemplate = () => {
        return (
            <div className="my-2">
                <Button
                    disabled={
                        lazyState.current.CompanyFilter == null &&
                            lazyState.current.ParticipantNameFilter == null &&
                            lazyState.current.BoardingCityFilter == null &&
                            lazyState.current.EmailFilter == null &&
                            lazyState.current.MobileNoFilter == null &&
                            lazyState.current.serialnumber == null &&
                            lazyState.current.inbatchfilter == null &&
                            lazyState.current.outbatchfilter == null &&
                            lazyState.current.IsArrivedfilter == null &&
                            lazyState.current.IsCheckedInfilter == null &&
                            lazyState.current.HotelFilter == null &&
                            lazyState.current.CityFilter == null &&
                            lazyState.current.Designationfilter == null &&
                            lazyState.current.ArrivalTimefilter == null &&
                            lazyState.current.DepartureTimefilter == null &&
                            lazyState.current.flightNo == null &&
                            lazyState.current.returnflightno == null && lazyState.current.Toeventcityfilter == null && lazyState.current.Fromeventcityfilter == null &&
                            lazyState.current.day1presentfilter == null && lazyState.current.day2presentfilter == null
                            ? true
                            : false
                    }
                    icon="pi pi-filter-slash"
                    className="p-button-outlined mr-2"
                    onClick={() => removeFilter()}
                    tooltip="Reset Filter"
                    tooltipOptions={{ position: "bottom", className: "showtooltip" }}
                    outlined
                ></Button>
            </div>
        );
    };

    return (
        <div className="trico-Grid">
            <Toast ref={toast} />
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <>
                    <div className="p-col">
                        <div className="card">
                            <h5 className="headerfont">
                                <span>
                                    <i className="fa fa-solid fa-users mr-2"></i>Attendees Status
                                </span>
                            </h5>
                            <Toolbar right={rightToolbarTemplate} className="mb-3"></Toolbar>
                            <Tooltip target="#mailto" content="Mail To" />
                            <Tooltip target="#callto" content="Call To" />
                            <DataTable size="small" lazy rows={lazyState.current.rows} onSort={onSort} sortField={lazyState.current.sortField} sortOrder={lazyState.current.sortOrder} removableSort stripedRows ref={dt} value={participantlist} responsiveLayout="scroll" filterDisplay={"row"}>
                                <Column field="serialNumber" header="Id" sortField="SerialNumber" sortable frozen headerStyle={{ width: "3%", minWidth: "5rem" }} filter showFilterMenu={false} filterElement={SerialNumberFilterTemplate} showClearButton></Column>
                                <Column sortField="FullName" field="fullName" frozen header="Name" sortable headerStyle={{ width: "15%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} filter showFilterMenu={false} filterElement={UserFilterTemplate} showClearButton></Column>
                                <Column field="isCheckedInYesNo" sortField="IsCheckedInYesNo" sortable header="Is Checked In" headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} filter showFilterMenu={false} filterElement={IsCheckedInFilterTemplate}></Column>
                                <Column field="isArrivedYesNo" sortField="IsArrivedYesNo" sortable header="Is Arrived" headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} filter showFilterMenu={false} filterElement={IsArrivedFilterTemplate}></Column>
                                <Column field="presentYesNo" sortField="PresentYesNo" sortable header="Day-1 Present" headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} filter showFilterMenu={false} filterElement={Day1PresentFilterTemplate}></Column>
                                <Column field="dayTwoPresentYesNo" sortField="DayTwoPresentYesNo" sortable header="Day-2 Present" headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} filter showFilterMenu={false} filterElement={Day2PresentFilterTemplate}></Column>
                                <Column field="hotelName" sortField="HotelName" sortable header="Hotel" headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} filter showFilterMenu={false} filterElement={HotelFilterTemplate}></Column>
                                <Column field="flightNo" sortField="FlightNo" header="Flight No" sortable headerStyle={{ width: "15%", minWidth: "15rem" }} filter showFilterMenu={false} filterElement={FlightNoFilterTemplate} style={{ overflowWrap: "anywhere" }}></Column>
                                <Column field="returnFlightNo" sortField="ReturnFlightNo" header="Return Flight No" sortable headerStyle={{ width: "15%", minWidth: "15rem" }} filter showFilterMenu={false} filterElement={ReturnFlightNoFilterTemplate} style={{ overflowWrap: "anywhere" }}></Column>
                                <Column
                                    field="arrivalTime"
                                    sortField="ArrivalTime"
                                    sortable
                                    body={(rowData) => <span>{rowData?.arrivalTime ? moment(rowData.arrivalTime).format("DD/MM/YYYY HH:mm") : ""}</span>}
                                    headerStyle={{ width: "10%", minWidth: "15rem" }}
                                    style={{ overflowWrap: "anywhere" }}
                                    header="Arrival"
                                    filter
                                    showFilterMenu={false}
                                    filterElement={ArrivalTimerFilterTemplate}
                                ></Column>
                                <Column
                                    field="departureTime"
                                    sortField="DepartureTime"
                                    sortable
                                    body={(rowData) => <span>{rowData?.departureTime ? moment(rowData.departureTime).format("DD/MM/YYYY HH:mm") : ""}</span>}
                                    headerStyle={{ width: "10%", minWidth: "15rem" }}
                                    style={{ overflowWrap: "anywhere" }}
                                    header="Departure"
                                    filter
                                    showFilterMenu={false}
                                    filterElement={DepartureTimerFilterTemplate}
                                ></Column>
                                <Column field="tricoConfirmed" sortField="TricoConfirmed" sortable header="Trico Confirmed" headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} filter showFilterMenu={false} filterElement={TricoConfirmedFilterTemplate} body={TricoConfirmedFilterBodyTemplate} filterField="tricoConfirmed" filterMenuStyle={{ width: '14rem' }} ></Column>
                                <Column field="inBatchBooking" header="In Batch" sortField="inBatchBooking" sortable filter headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere", minWidth: '14rem' }} showFilterMenu={false} filterElement={InBatchFilterTemplate} body={InBatchFilterBodyTemplate} filterField="InBatchBooking" filterMenuStyle={{ width: '14rem' }}></Column>
                                <Column field="outBatchBooking" header="Out Batch" sortField="outBatchBooking" sortable filter headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere", minWidth: '14rem' }} showFilterMenu={false} filterElement={OutBatchFilterTemplate} body={OutBatchFilterBodyTemplate} filterField="OutBatchBooking" filterMenuStyle={{ width: '14rem' }}></Column>
                                {/* <Column field="outBatchBooking" sortField="OutBatchBooking" sortable header="Out Batch" headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} filter showFilterMenu={false} filterElement={OutBatchFilterTemplate}></Column> */}
                                <Column body={ButtonPhoneto} sortField="MobileNo" field="mobileNo" header="Mobile No." sortable style={{ overflowWrap: "anywhere" }} filter showFilterMenu={false} filterElement={MobileFilterTemplate}></Column>
                                <Column body={ButtonMailto} sortField="Email" field="email" header="Email" sortable style={{ overflowWrap: "anywhere" }} filter showFilterMenu={false} filterElement={EmailFilterTemplate}></Column>
                                <Column field="designation" header="Designation" sortField="Designation" sortable headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} filter showFilterMenu={false} filterElement={DesignationFilterTemplate}></Column>
                                <Column field="cityname" header="City" filterField="cityname" sortField="cityname" sortable headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} filter showFilterMenu={false} filterElement={CityFilterTemplate}></Column>
                                <Column field="boardingcityname" header="BoardingCity" sortField="boardingcityname" sortable headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} filter showFilterMenu={false} filterElement={BoardingCityFilterTemplate}></Column>
                                <Column field="company" header="Company" sortField="company" sortable headerStyle={{ width: "25%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} filter showFilterMenu={false} filterElement={CompanyFilterTemplate} body={CompanyFilterBodyTemplate} filterField="Company" filterMenuStyle={{ width: '14rem' }} ></Column>
                                <Column field="travelmode_Inbound" header="Travel In Bound" sortField="Travelmode_Inbound" sortable headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} filter showFilterMenu={false} filterElement={ToEventCityTemplate}></Column>
                                <Column
                                    field="travelmode_Outbound"
                                    header="Travel Out Bound"
                                    sortField="Travelmode_Outbound"
                                    sortable
                                    headerStyle={{ width: "10%", minWidth: "15rem" }}
                                    style={{ overflowWrap: "anywhere" }}
                                    filter
                                    showFilterMenu={false}
                                    filterElement={FromEventCityTemplate}
                                ></Column>
                            </DataTable>
                            <Paginator className="justify-content-end" style={{ marginRight: "15px" }} first={lazyState.current.first} rows={lazyState.current.rows} totalRecords={totalRecords} template={template2} rowsPerPageOptions={RowsPerPageDropdown} onPageChange={onBasicPageChange}></Paginator>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default ViewAttendeeStatus;
